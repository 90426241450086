import React from 'react'

const Processing = (isWait) => {
    return (
        <div className={isWait ? 'modal is-active' : 'modal'}>
            <div className="modal-background" />
            <div className="modal-card">
            <p className="modal-card-body has-text-centered">処理中・・・<br/>しばらくお待ちください。</p>
            </div>
        </div>
    )
}
export default Processing
