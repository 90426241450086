import React, {useState, useEffect, useRef} from 'react';

import Header from './Header';
import Body from './Body'

const SpreadSheet = (props) => {
  const spreadSheetRef = useRef(null);

  return (
    <table className="table is-fullwidth is-bordered spreadsheet" ref={spreadSheetRef}>
      <Header headers={props.headers} />
      <Body
        spreadSheetRef={spreadSheetRef}
        rowsRef={props.rowsRef}
        datasheetRef={props.datasheetRef}
        setDatasheet={props.setDatasheet}
        deleteLine={props.deleteLine} />
    </table>
  )
}

export default SpreadSheet;