import React, {useState, useEffect} from 'react'
import apicall from '../utils/apicall'
import useCookie from "../utils/useCookie"

const Logout = () => {
    const url = "logout"
    const[cookiePeriods_id, setCookiePeriods_id] = useCookie("periods_id", "")
    const[cookieRole, setCookieRole] = useCookie("role", "")
    const[cookieId, setCookieId] = useCookie("id", "")
    
    useEffect(async() => {
        setCookiePeriods_id("", -1)
        setCookieRole("", -1)
        setCookieId("", -1)
    }, [])

    return(
        <article>
            <div>
                <br />
                <p>ログアウトしました。<br />
                ブラウザを閉じて終了してください。</p>
            </div>
        </article>
    )
}


export default Logout