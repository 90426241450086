import React from 'react'
import Cell from './Cell'
import UnCopiebleCell from './UnCopiableCell'

const Row = (props) => {

  return (
    <tr>
      {props.row.map((cell, i) =>
        cell.options.type != 'indextype' ? 

          <Cell
            key={i+'-'+props.y}
            index={i+'-'+props.y}
            x={i}
            y={props.y}
            cell={cell}
            editableRef={props.editableRef}
            setEditable={props.setEditable}
            isSelecting={props.isSelecting}
            setIsSelecting={props.setIsSelecting}
            firstSelectXY={props.firstSelectXY}
            setFirstSelectXY={props.setFirstSelectXY}
            endSelectXY={props.endSelectXY}
            setEndSelectXY={props.setEndSelectXY}
            handleMoveEndSelect={props.handleMoveEndSelect}
            datasheetRef={props.datasheetRef}
            setDatasheet={props.setDatasheet}
            setIsEditing={props.setIsEditing} />
        :
          <UnCopiebleCell
            key={i+'-'+props.y}
            index={i+'-'+props.y}
            x={i}
            y={props.y}
            cell={cell}
            editableRef={props.editableRef}
            setEditable={props.setEditable}
            isSelecting={props.isSelecting}
            setIsSelecting={props.setIsSelecting}
            firstSelectXY={props.firstSelectXY}
            setFirstSelectXY={props.setFirstSelectXY}
            endSelectXY={props.endSelectXY}
            setEndSelectXY={props.setEndSelectXY}
            handleMoveEndSelect={props.handleMoveEndSelect}
            datasheetRef={props.datasheetRef}
            setDatasheet={props.setDatasheet}
            deleteLine={props.deleteLine}
            setIsEditing={props.setIsEditing} />
      )}
    </tr>
  )
}

export default Row;