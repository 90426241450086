import React, {useState, useEffect} from 'react'
import apicall from '../utils/apicall'
import useCookie from "../utils/useCookie"
import AlertDialog from './AlertDialog'
import DownLoad from './DownLoad'

const ScheduleOutputs = () => {
  const[cookiePeriods_id, setCookiePeriods_id] = useCookie("periods_id", "")
  const[districtListResponse, setDistrictListResponse] = useState([])
  const[district, setDistrict] = useState("")
  const[period_id, setPeriod_id] = useState("4")
  const[isComplete, setIsComplete] = useState(false)
  const[completeMsg, setCompleteMsg] = useState("")

  const url = "schedule-outputs"
  const url_m = "m-districts-list/except-head"

  useEffect(async() => {
    const data = await apicall(url_m, "get")
    if (data) {
        setDistrictListResponse(data)
    }
    setPeriod_id(cookiePeriods_id)
  }, [])

  const output = async(type) => {
    var csv_url
    // 出力
    var prm = "?periods_id=" + period_id + "&m_districts_id=" + district
    switch(type) {
      case 1:
        csv_url = url + "/csv-factory-schedules" + prm
        setCompleteMsg("デスクトップの帳票出力フォルダの中の「工場審査希望スケジュール一覧表」を実行してください")
        setIsComplete(true)
        break
      case 2:
        csv_url = url + "/csv-judge-schedules" + prm + "&evaluator_flag=true"
        setCompleteMsg("デスクトップの帳票出力フォルダの中の「評価員希望スケジュール一覧表」を実行してください")
        setIsComplete(true)
        break
      case 3:
        csv_url = url + "/csv-judge-schedules" + prm + "&evaluator_flag=false"
        setCompleteMsg("デスクトップの帳票出力フォルダの中の「調査員希望スケジュール一覧表」を実行してください")
        setIsComplete(true)
        break
      default:
        return
    }

    const response = await apicall(csv_url, "get", {}, false)
    if (response) {
      let disposition = response.headers.get("content-disposition")
      DownLoad(disposition, await response.blob())
    }
  }

  const clearMsg = () => {
    setCompleteMsg("")
    setIsComplete(false)
  }

  return(
    <article>
      {AlertDialog(isComplete, false, completeMsg, "", clearMsg)}
      <div className="level">
      <label className="label">希望スケジュール出力機能</label>
      </div>
      <table className="has-text-left">
        <tr>
          <td>
            <label className="subtitle">地区</label>
          </td>
          <td>
            <select className="select" value={district} style={{width: 100}}
                  onChange={(e) => {setDistrict(e.target.value)}}>
                  <option value={""}>{""}</option>
                  {districtListResponse.map((row, rowIndex) => 
                      <option value={row["id"]}>{row["name"]}</option>
                  )}
            </select>
          </td>
        </tr>
        <tr><td><br/></td></tr>
        <tr className="" >
          <td className="has-text-centered" colSpan={2}>
          <button className="button is-info" onClick={() => output(1)}>工場希望スケジュール表出力</button>
          </td>
          <td width="30">
          </td>
          <td className="has-text-centered" colSpan={2}>
          <button className="button is-info" onClick={() => output(2)}>評価員希望スケジュール表出力</button>
          </td>
          <td width="30">
          </td>
          <td className="has-text-centered" colSpan={2}>
          <button className="button is-info" onClick={() => output(3)}>調査員希望スケジュール表出力</button>
          </td>
        </tr>
      </table>
    </article>
  )
}

export default ScheduleOutputs