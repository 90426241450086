import React, {useState, useEffect, useRef} from 'react';

// スプレッドシート仕様

// 【単体選択時】
// delete : 削除
// backspace : フォーカス + 削除
// arrow : 矢印の方向に１移動
// Fn2 : フォーカス
// Ctrl + C : コピー
// Ctrl + V : ペースト（tab区切りは水平方向に１ずつ移動して貼り付け）
// その他文字 : フォーカス + 削除 + 入力された文字を入れる

// 【複数選択時】
// delete : 全削除
// backspace : スタートセルにフォーカス + 削除
// arrow : スタートセルを矢印方向に１移動 + エンドセルを削除
// Fn2 : スタートセルにフォーカス
// Ctrl + C : 水平セルはtab区切り、垂直セルはCRLF
// Ctrl + V : ペースト※

// ※ クリップボードの要素にtabがなければ範囲内をクリップボードの内容で全書き換え,
// tabがあればスタートセルからtab区切りに水平方向に１ずつ移動して貼り付け

const Cell = (props) => {

  const cellRef = useRef()
  const documentClickHandler = useRef()
  const documentMouseUpHandler = useRef()

  const [cellValue, setCellValue] = useState(props.cell.value)

  useEffect(() => {
    documentClickHandler.current = e => {
      if (cellRef.current.contains(e.target)) return

      props.setEditable("")

      removeDocumentClickHandler()
    }

    documentMouseUpHandler.current = e => {
      if (cellRef.current.contains(e.target)) return
      props.setIsSelecting(false)
      removeDocumentMouseUpHandler()
    }

  }, [])

  const removeDocumentClickHandler = () => {
    document.removeEventListener('click', documentClickHandler.current)
  }

  const removeDocumentMouseUpHandler = () => {
    document.removeEventListener('mouseup', documentMouseUpHandler.current)
  }

  const handleCellSelectOnMouseDown = () => {
    props.setFirstSelectXY({x: props.x, y: props.y})
    props.setEndSelectXY({})

    document.addEventListener('mouseup', documentMouseUpHandler.current)
  }

  const handleCellSelectOnMouseOver = () => {
    if (props.isSelecting) {
      props.handleMoveEndSelect(props.x, props.y);
      props.setEditable("")
    }
  }

  const isSelectedCell = () => {
    if (!Object.keys(props.endSelectXY).length) {
      return props.x == props.firstSelectXY.x && props.y == props.firstSelectXY.y
    }

    // 並行移動(+)
    if (props.endSelectXY.x >= props.firstSelectXY.x
        && props.endSelectXY.y == props.firstSelectXY.y) {
      return props.endSelectXY.x >= props.x
        && props.x >= props.firstSelectXY.x
        && props.y == props.firstSelectXY.y 
        && props.y == props.endSelectXY.y
    }
      

    // 並行移動(-)
    if (props.firstSelectXY.x >= props.endSelectXY.x
      && props.firstSelectXY.y == props.endSelectXY.y) {
      return props.x >= props.endSelectXY.x
        && props.firstSelectXY.x >= props.x
        && props.y == props.firstSelectXY.y 
        && props.y == props.endSelectXY.y
    }

    // 垂直移動(+)
    if (props.endSelectXY.y >= props.firstSelectXY.y
      && props.endSelectXY.x == props.firstSelectXY.x) {
      return props.endSelectXY.y >= props.y
        && props.y >= props.firstSelectXY.y
        && props.x == props.firstSelectXY.x
        && props.x == props.endSelectXY.x
    }

    // 垂直移動(-)
    if (props.firstSelectXY.y >= props.endSelectXY.y
      && props.firstSelectXY.x == props.endSelectXY.x) {
      return props.y >= props.endSelectXY.y
        && props.firstSelectXY.y >= props.y
        && props.x == props.firstSelectXY.x
        && props.x == props.endSelectXY.x
    }

    // 並行移動(+),垂直移動(+)
    if (props.endSelectXY.x >= props.firstSelectXY.x
      && props.endSelectXY.y >= props.firstSelectXY.y) {
      return props.endSelectXY.x >= props.x
        && props.x >= props.firstSelectXY.x
        && props.endSelectXY.y >= props.y
        && props.y >= props.firstSelectXY.y
    }

    // 並行移動(+),垂直移動(-)
    if (props.endSelectXY.x >= props.firstSelectXY.x
      && props.firstSelectXY.y >= props.endSelectXY.y) {
      return props.endSelectXY.x >= props.x
        && props.x >= props.firstSelectXY.x
        && props.y >= props.endSelectXY.y
        && props.firstSelectXY.y >= props.y
    }

    // 並行移動(-),垂直移動(+)
    if (props.firstSelectXY.x >= props.endSelectXY.x
      && props.endSelectXY.y >= props.firstSelectXY.y) {
      return props.x >= props.endSelectXY.x
        && props.firstSelectXY.x >= props.x
        && props.endSelectXY.y >= props.y
        && props.y >= props.firstSelectXY.y
    }

    // 並行移動(-),垂直移動(-)
    if (props.firstSelectXY.x >= props.endSelectXY.x
      && props.firstSelectXY.y >= props.endSelectXY.y) {
      return props.x >= props.endSelectXY.x
        && props.firstSelectXY.x >= props.x
        && props.y >= props.endSelectXY.y
        && props.firstSelectXY.y >= props.y
    }
  }

  const updateDataSheet = (value) => {
    var sheet = props.datasheetRef.current
    sheet[props.index] = value
    props.setDatasheet(sheet)
  }

  return (
    <td
      className={
        props.cell.options.cell_info !== '3' && isSelectedCell()
        ? props.cell.options.fixed === undefined ? 'is-active' : 'is-active ' + props.cell.options.fixed
        : props.cell.options.cell_info === '5' ? 
          props.cell.options.fixed === undefined ? 'is-sameday' : 'is-sameday ' + props.cell.options.fixed
        : props.cell.options.cell_info === '4' ?
          props.cell.options.fixed === undefined ? 'is-gray' : 'is-gray ' + props.cell.options.fixed
        : props.cell.options.cell_info === '3' ?
          props.cell.options.fixed === undefined ? 'is-black' : 'is-black ' + props.cell.options.fixed
        : props.cell.options.cell_info === '2' ?
          props.cell.options.fixed === undefined ? 'is-sunday' : 'is-sunday ' + props.cell.options.fixed
        : props.cell.options.cell_info === '1' ?
          props.cell.options.fixed === undefined ? 'is-saturday' : 'is-saturday ' + props.cell.options.fixed
        : '' + props.cell.options.fixed === undefined ? '' : props.cell.options.fixed}
      ref={cellRef}
      onMouseDown={() => {handleCellSelectOnMouseDown(); props.setIsSelecting(true)}}
      onMouseUp={() => {props.setIsSelecting(false);}}
      onMouseOver={() => {handleCellSelectOnMouseOver();}}
      onClick={() => {
        props.setEditable(props.index);
        // handleShowCellInput();
        }}
      >

      {props.cell.options.type === 'indextype' 
        ?
          <span className="icon-text-no-wrap">
            <span>{props.y + 1}</span>
            <span className="icon">
              <i className="fas fa-trash-alt"></i>
            </span>
          </span>

        : props.editableRef.current != props.index
        && props.cell.options.cell_info !== '3' 
        && props.cell.options.type !== 'indextype' ? 

          props.datasheetRef.current[props.index] : 

            props.cell.options.type === 'input' && props.editableRef.current == props.index ? 
              <input
                type={props.editableRef.current == props.index ? 'text' : 'hidden'}
                onChange={(e) => {
                  updateDataSheet(e.target.value);
                  setCellValue(e.target.value);
                }}
                onFocus={() => props.setIsEditing(true)}
                onBlur={() => props.setIsEditing(false)}
                value={props.datasheetRef.current[props.index]} />
            : props.cell.options.type === 'dropdown' && props.editableRef.current == props.index ? 
              <select onChange={(e) => {
                updateDataSheet(e.target.value);
                setCellValue(e.target.value);
              }}>
                {props.cell.options.source.map((op) =>
                <option selected={op == props.cell.value}>{op}</option>)}
              </select>
            : props.datasheetRef.current[props.index]
      }
    
    </td>
  )
};

export default Cell;