import React, {useState} from 'react'

const ModalDialog = (props) => {

  return (
    <div className={props.isShow ? 'modal is-active' : 'modal'}>
        <div className="modal-background" />
        <article className={
          props.messageAttribute != "" || props.messageAttribute != undefined
            ? "message " + props.messageAttribute
            : "message is-info"}>
            <div className="message-header">
                <p>{props.title}</p>
                <div style={{ flexGrow: 1 }}></div>
                <button className="delete" aria-label="close" onClick={() => props.close()}/>
            </div>
            <div className="message-body notification">
                {props.message.split('\n').map(m => <p>{m}</p>)}
                <br />
                <button className="button" aria-label="ok" onClick={() => props.ok(true)}>
                    <p>OK</p>
                </button>
                {props.messageAttribute === "is-warning"
                  ? <button className="button" aria-label="cancel" onClick={() => props.close()}>
                      <p>キャンセル</p>
                    </button>
                  : ""
                }
            </div>
        </article>
    </div>
  )
}

export default ModalDialog