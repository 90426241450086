import React, {useState} from 'react'
import apicall from '../utils/apicall'
import AlertDialog from './AlertDialog'
import ModalDialog from './ModalDialog'

const PeriodSetting = () => {
    const[period_id, setPeriod_id] = useState("")
    const[x_id, setX_id] = useState("")
    const[a_id, setA_id] = useState("")
    const[b_id, setB_id] = useState("")
    const[c_id, setC_id] = useState("")
    const[d_id, setD_id] = useState("")
    const[e_id, setE_id] = useState("")
    const[z_id, setZ_id] = useState("")
    const[yaer, setYaer] = useState("")
    const[period, setPriod] = useState("0")
    const[x_start, setX_start] = useState("")
    const[x_end, setX_end] = useState("")
    const[a_start, setA_start] = useState("")
    const[a_end, setA_end] = useState("")
    const[b_start, setB_start] = useState("")
    const[b_end, setB_end] = useState("")
    const[c_start, setC_start] = useState("")
    const[c_end, setC_end] = useState("")
    const[d_start, setD_start] = useState("")
    const[d_end, setD_end] = useState("")
    const[e_start, setE_start] = useState("")
    const[e_end, setE_end] = useState("")
    const[z_start, setZ_start] = useState("")
    const[z_end, setZ_end] = useState("")
    // const[input_start, setInput_start] = useState("")
    const[input_end, setInput_end] = useState("")
    const[impossible1, setimpossible1] = useState("")
    const[impossible2, setimpossible2] = useState("")
    const[impossible3, setimpossible3] = useState("")
    const[isShowModal, setIsShowModal] = useState(false)
    const[isError, setIsError] = useState(false)
    const[errorMsg, setErrorMsg] = useState("")

    const[isShowDialog, setIsShowDialog] = useState(false)
    const[dialogMessage, setDialogMessage] = useState("")
    const[dialogMessageAttribute, setDialogMessageAttribute] = useState("")

    const url = "period-setting"

    const onChangeYearPeriod = async(p_year, p_period) => {
        if (p_year.length != 4) {
            return ""
        }
        const data = await apicall(url + "?year=" + p_year + "&period=" + p_period, "get")
        if (data) {
            setPeriod_id(data.period_id)

            setX_id(data.x_id)
            setX_start(data.x_start)
            setX_end(data.x_end)

            setA_id(data.a_id)
            setA_start(data.a_start)
            setA_end(data.a_end)

            setB_id(data.b_id)
            setB_start(data.b_start)
            setB_end(data.b_end)

            setC_id(data.c_id)
            setC_start(data.c_start)
            setC_end(data.c_end)

            setD_id(data.d_id)
            setD_start(data.d_start)
            setD_end(data.d_end)

            setE_id(data.e_id)
            setE_start(data.e_start)
            setE_end(data.e_end)

            setZ_id(data.z_id)
            setZ_start(data.z_start)
            setZ_end(data.z_end)

            // setInput_start(data.input_date_from)
            setInput_end(data.input_date_to)

            setimpossible1(data.impossible_day1)
            setimpossible2(data.impossible_day2)
            setimpossible3(data.impossible_day3)
        }
    }

    const isSaveError = () => {
        let msg = ""
        if (yaer == "") {
            msg = msg + "年度が未入力です。\n"
        }
        if (period == "") {
            msg = msg + "期が未入力です。\n"
        }
        if (a_start == "" || a_end == "" ||
            b_start == "" || b_end == "" ||
            c_start == "" || c_end == "" ||
            d_start == "" || d_end == "" ||
            e_start == "" || e_end == "" ) {
            msg = msg + "審査期間A～Eに未入力があります。\n"
        }
        if (msg != "") {
            setIsError(true)
            setErrorMsg(msg)
            return true
        }
        return false
    }
    const clearError = () => {
        setIsError(false)
        setErrorMsg("")
    }
    const saveClick = () => {
        if (isSaveError()) {
            return
        }
        if (period_id === undefined || period_id === "") {
            post_request_period_setting()
        } else {
            setIsShowModal(true)
        }
    } 
    const post_request_period_setting = async() => {
        if (isError) {
            return
        }

        const data = await apicall(url, "post", {
            period_id:period_id,
            yaer: yaer,
            period: period,
            x_id: x_id,
            x_start : x_start,
            x_end : x_end,
            a_id: a_id,
            a_start: a_start,
            a_end: a_end,
            b_id: b_id,
            b_start:b_start, 
            b_end: b_end,
            c_id: c_id,
            c_start:c_start,
            c_end: c_end,
            d_id: d_id,
            d_start: d_start,
            d_end: d_end,
            e_id: e_id,
            e_start: e_start,
            e_end: e_end,
            z_id: z_id,
            z_start: z_start,
            z_end: z_end,
            // input_start: input_start,
            input_end : input_end,
            impossible1 : impossible1,
            impossible2 : impossible2,
            impossible3 : impossible3
        })
        if (data) {
            if (data.isSuccess) {
                setIsShowDialog(true)
                setDialogMessageAttribute("is-info")
                setDialogMessage(data.message)
                setPeriod_id(data.period_id)
                onChangeYearPeriod(yaer, period)

            } else {
                setIsShowDialog(true)
                setDialogMessageAttribute("is-danger")
                setDialogMessage(data.message)
            }
        }
    }

    const confirmUpdate = () => {
        post_request_period_setting()
        setIsShowModal(false)
    }

    const dialogClose = () => {
        setIsShowDialog(false)
        setDialogMessageAttribute("")
        setDialogMessage("")
    }

  return(
    <article>
        <ModalDialog isShow={isError} messageAttribute={"is-danger"} title={"未入力の箇所があります。"} message={errorMsg} close={clearError} ok={clearError} />
        <ModalDialog isShow={isShowDialog} messageAttribute={dialogMessageAttribute} title={""} message={dialogMessage} close={dialogClose} ok={dialogClose} />

        <div className="level">
        <label className="title">業務期間設定</label>
        </div>
        <div className="level">
        <table className="has-text-left">
            <tr>
                <td width={100}><label>年度</label></td>
                <td>
                    <input className="input" type="text" style={{width: 100}} maxLength={4} onChange={(e) => {setYaer(e.target.value); onChangeYearPeriod(e.target.value, period);}}/>
                </td>
            </tr>
            <tr><td><br/></td></tr>
            <tr>
                <td><label>期</label></td>
                <td>
                    <select className="select" style={{width: 100}} value={period}
                        onChange={(e) => {setPriod(e.target.value); onChangeYearPeriod(yaer, e.target.value);}}>
                        <option value="0">前期</option>
                        <option value="1">後期</option>
                    </select>
                </td>
            </tr>
        </table>
        </div>
        
        <div className="level">
        <table className="has-text-left">
            <tr>
                <td colSpan={2}><label>審査期間</label></td>
            </tr>
            <tr>
                <td></td>
                <td style={{ flexDirection: 'column' }}><label>前倒し(X) :</label></td>
                <td>
                    <input className="input" type="date" value={x_start} onChange={(e) => setX_start(e.target.value)}/>
                </td>
                <td className="has-text-centered"><label>～</label></td>
                <td>
                    <input className="input" type="date" value={x_end} onChange={(e) => setX_end(e.target.value)}/>
                </td>
            </tr>
            <tr>
                <td></td>
                <td><label>A :</label></td>
                <td>
                    <input className="input" type="date" value={a_start} onChange={(e) => setA_start(e.target.value)}/>
                </td>
                <td className="has-text-centered"><label>～</label></td>
                <td>
                    <input className="input" type="date" value={a_end} onChange={(e) => setA_end(e.target.value)}/>
                </td>
            </tr>
            <tr>
                <td></td>
                <td><label>B :</label></td>
                <td>
                    <input className="input" type="date" value={b_start} onChange={(e) => setB_start(e.target.value)}/>
                </td>
                <td className="has-text-centered"><label>～</label></td>
                <td>
                    <input className="input" type="date" value={b_end} onChange={(e) => setB_end(e.target.value)}/>
                </td>
            </tr>
            <tr>
                <td></td>
                <td><label>C :</label></td>
                <td>
                    <input className="input" type="date" value={c_start} onChange={(e) => setC_start(e.target.value)}/>
                </td>
                <td className="has-text-centered"><label>～</label></td>
                <td>
                    <input className="input" type="date" value={c_end} onChange={(e) => setC_end(e.target.value)}/>
                </td>
            </tr>
            <tr>
                <td></td>
                <td><label>D :</label></td>
                <td>
                    <input className="input" type="date" value={d_start} onChange={(e) => setD_start(e.target.value)}/>
                </td>
                <td className="has-text-centered"><label>～</label></td>
                <td>
                    <input className="input" type="date" value={d_end} onChange={(e) => setD_end(e.target.value)}/>
                </td>
            </tr>
            <tr>
                <td></td>
                <td><label>E :</label></td>
                <td>
                    <input className="input" type="date" value={e_start} onChange={(e) => setE_start(e.target.value)}/>
                </td>
                <td className="has-text-centered"><label>～</label></td>
                <td>
                    <input className="input" type="date" value={e_end} onChange={(e) => setE_end(e.target.value)}/>
                </td>
            </tr>
            <tr>
                <td></td>
                <td><label>延長(Z):</label></td>
                <td>
                    <input className="input" type="date" value={z_start} onChange={(e) => setZ_start(e.target.value)}/>
                </td>
                <td className="has-text-centered"><label>～</label></td>
                <td>
                    <input className="input" type="date" value={z_end} onChange={(e) => setZ_end(e.target.value)}/>
                </td>
            </tr>
            <tr><td><br/></td></tr>
            <tr>
                <td colSpan={2}><label>評価員/調査員スケジュール入力期間　</label></td>
                {/* <td>
                    <input className="input" type="date" value={input_start} onChange={(e) => setInput_start(e.target.value)}/>
                </td>
                <td className="has-text-centered"><label>～</label></td> */}
                <td>
                    <input className="input" type="date" value={input_end} onChange={(e) => setInput_end(e.target.value)}/>
                </td>
            </tr>
            <tr><td><br/></td></tr>
            <tr>
                <td colSpan={2}><label>審査不可日</label></td>
                <td>
                    <input  className="input" type="date" value={impossible1} onChange={(e) => setimpossible1(e.target.value)}/>
                </td>
                <td width={50}></td>
                <td>
                    <input  className="input" type="date" value={impossible2} onChange={(e) => setimpossible2(e.target.value)}/>
                </td>
                <td width={50}></td>
                <td>
                    <input  className="input" type="date" value={impossible3} onChange={(e) => setimpossible3(e.target.value)}/>
                </td>
            </tr>
            <tr><td><br/></td></tr>
            <tr>
                <td className="level" colSpan={7}>
                    <button className="button is-info" onClick={() => saveClick()}>　保存　</button>
                </td>
            </tr>
        </table>
        </div>
      {/* 更新ダイアログ */}
      <div className={isShowModal ? 'modal is-active' : 'modal'}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title has-text-left">業務期間更新ダイアログ</p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => setIsShowModal(false)}
            />
          </header>
          <section className="modal-card-body">
            <p className="has-text-left">【注意】<br/>
            現在、入力されている内容で、業務期間設定の更新を行います。<br/>
            設定済みの審査期間などを更新する場合、対象の審査期間で入力されたスケジュールにデータに
            影響を及ぼすことがありますので、ご注意ください。</p>
          </section>
          <footer className="modal-card-foot">
            <button
              className="button is-success"
              onClick={() => confirmUpdate()}
            >
              <span className="icon">
                <i className="fas fa-info-circle" />
              </span>
              <p>更新</p>
            </button>
            <button
              className="button"
              onClick={() => setIsShowModal(false)}
            >
              <span className="icon">
                <i className="fas fa-undo" />
              </span>
              <p>キャンセル</p>
            </button>
          </footer>
        </div>
      </div>
    </article>
  )
}

export default PeriodSetting