import React, {useState} from 'react'

const JudgeScheduleHelp = (setIsShowHelp) => {
    const[isHelpCalendar, setIsHelpCalendar] = useState(true)
    const[isHelpAllSet, setIsHelpAllSet] = useState(true)
    const[isHelpButton, setIsHelpButton] = useState(true)
    const getHelpValue = (isView) => {
        return isView ? "－" : "＋";
    }
  
    return(
        <article>
            <div className="modal-background"  onClick={() => setIsShowHelp(false)} />
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="has-text-left">評価員・調査員スケジュール入力 - ヘルプ</p>
                <div style={{ flexGrow: 1 }}></div>
                <button className="delete" aria-label="close" onClick={() => setIsShowHelp(false)} />
              </header>
              <section className="modal-card-body">
                <div className>
                  <table className="has-text-left">
                    <tr>
                      <td>
                        <button onClick={() => setIsHelpCalendar(!isHelpCalendar)}>{getHelpValue(isHelpCalendar)}</button>
                      </td>
                      <td>
                        <label className="subtitle">カレンダー部分に関する説明</label>
                      </td>
                    </tr>
                    <tr>
                      <td>
                      </td>
                      <td>
                        <div style={{ display: isHelpCalendar ? '' : 'none' }}>
                          <p className="has-text-left">
                            カレンダーに空欄の残らないよう、土日も含め、全日程にいずれかの印を入力してください。<br/>
                            完了後、入力内容は全鉄評に送信されるため、編集することができなくなります。<br/>
                            スケジュールを入力する際は、以下の表を参考に、内容を選択してください。
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <div style={{ display: isHelpCalendar ? '' : 'none' }}>
                          <table className="table is-fullwidth is-bordered has-text-left">
                            <tr>
                              <th>スケジュール</th>
                              <th>選択値</th>
                            </tr>
                            <tr>
                              <td>都合の良い日（全日）</td>
                              <td>〇</td>
                            </tr>
                            <tr>
                              <td>都合の悪い日（全日）</td>
                              <td>×</td>
                            </tr>
                            <tr>
                              <td>未確定</td>
                              <td>△</td>
                            </tr>
                            <tr>
                              <td>午前の都合が良い日</td>
                              <td>AM</td>
                            </tr>
                            <tr>
                              <td>午後の都合が良い日</td>
                              <td>PM</td>
                            </tr>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div><br/></div>
                <div>
                  <table className="has-text-left">
                    <tr>
                      <td>
                        <button onClick={() => setIsHelpAllSet(!isHelpAllSet)}>{getHelpValue(isHelpAllSet)}</button>
                      </td>
                      <td>
                        <label className="subtitle">一括変更ボタンに関する説明</label>
                      </td>
                    </tr>
                    <tr>
                      <td>
                      </td>
                      <td>
                        <div style={{ display: isHelpAllSet ? '' : 'none' }}>
                          <p className="has-text-left">
                            プルダウンで選択した印（○、×、△）を、カレンダーの空欄に一括入力します。<br/>
                            印の選択後、一括変更ボタンをクリックしてください。
                          </p>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div><br/></div>
                <div>
                  <table className="has-text-left">
                    <tr>
                      <td>
                        <button onClick={() => setIsHelpButton(!isHelpButton)}>{getHelpValue(isHelpButton)}</button>
                      </td>
                      <td>
                        <label className="subtitle">一時保存ボタンと完了ボタンに関する説明</label>
                      </td>
                    </tr>
                    <tr>
                      <td>
                      </td>
                      <td>
                        <div style={{ display: isHelpButton ? '' : 'none' }}>
                          <p className="has-text-left">
                            入力内容を一時保存する際は、一時保存ボタンをクリックしてください。<br/>
                            一時保存ボタンを押さずに画面を閉じた場合、入力内容が破棄されてしまいます。<br/>
                            また、全日程のスケジュール入力が完了した際は、完了ボタンをクリックしてください。<br/>
                            完了ボタンのクリックをもって、全鉄評に入力頂いたスケジュールを送信します。
                          </p>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </section>
              <footer className="modal-card-foot">
              </footer>
            </div>
        </article>
    )
}
export default JudgeScheduleHelp