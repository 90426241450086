import React,{useState, useEffect} from 'react';

const Header = (props) => {
  const[tableDepth, setTableDepth] = useState(0)

  const[flatColumns, setFlatColumns] = useState([])

  useEffect(() => {
    header_builder()
  }, [props.headers])

  const header_builder = () => {
    // ヘッダーの深さ
    let depths = []
    props.headers.map((h) => {
      if (h.children) {
        depths = search_depth(h, 1, depths)
      } else {
        depths = [1]
      }
    })

    let t_depth = 0
    depths.filter((d) => t_depth = t_depth < d ? d : t_depth)
    
    setTableDepth(t_depth)

    let flc_list = []
    props.headers.map((h) => {
      // 深さ n = 0
      flc_list.push({
        depth: 0,
        title: h.title,
        fixed: h.fixed === undefined ? '' : h.fixed,
        // rowspanはhas_children=trueなら1,has_children=falseなら全体の深さ
        rowspan: h.children ? 1 : t_depth,
        // colspanはhas_children=trueなら終端要素の数,has_children=falseなら1
        colspan: h.children ? search_end_nodes(h, []).length : 1
      })

      expose_children(h, 0, t_depth - 1, flc_list) // 全体の深さ-1を渡す
    })

    setFlatColumns(flc_list)
  }

  const search_depth = (h, depth, depths) => {
    if (h.children) {
      depths.push(depth + 1)

      h.children.map((hc) => {
        search_depth(hc, depth + 1, depths)
      })
    }

    return depths
  }

  const search_end_nodes = (h, end_nodes) => {
    if (h.children){
      h.children.map((hc) => {
        search_end_nodes(hc, end_nodes)
      })
    } else {
      end_nodes.push(h.title)
    }
    return end_nodes;
  }

  const expose_children = (h, current_depth, t_depth, flc_list) => {
    if (h.children) {
      current_depth = current_depth + 1
      h.children.map((hc) => {
        // 深さ n + 1
        flc_list.push({
          depth: current_depth,
          title: hc.title,
          fixed: hc.fixed === undefined ? '' : hc.fixed,
          // rowspanはhas_children=falseなら渡されてきた深さ, has_children=trueなら1
          rowspan: hc.children ? 1 : t_depth,
          colspan: hc.children ? search_end_nodes(hc, []).length : 1
        })

        expose_children(hc, current_depth, t_depth - 1, flc_list) // 全体の深さ-1を渡す
      })
      
    }
  }

  return (
    <thead className='is-sticky'>
      {[...Array(tableDepth)].map((_, d) => 
        <tr>
          {flatColumns.map((fc, index) => 
            fc.depth == d
            ? <th
                className={fc.fixed}
                rowSpan={fc.rowspan}
                colSpan={fc.colspan == 0 ? 1 : fc.colspan}>
                  {fc.title}
              </th>
            : <></>
          )}
        </tr>
      )}

    </thead>
  )
}

export default Header;