import React, {useState, useEffect} from 'react'
import apicall from '../utils/apicall'
import MailSendPreview from './MailSendPreview'
import Processing from './Processing'
import useCookie from "../utils/useCookie"
import AlertDialog from './AlertDialog'

const MailJudges = () => {
  const url = "mail-judges"
  const url_m = "m-districts-list"
  const[cookiePeriods_id, setCookiePeriods_id] = useCookie("periods_id", "")
  const[listRow, setListRow] = useState([])
  const[districtsResponse, setDistrictsResponse] = useState([])
  const[attachFileNames, setAttachFileNames] = useState([])
  const[fileNames, setFileNames] = useState([])
  const[southOrNorth, setSouthOrNorth] = useState("")
  const[period_id, setPeriod_id] = useState("")
  const[m_districts_id, setM_districts_id] = useState("")
  const[isEvaluator, setIsEvaluator] = useState(false)
  const[isSurvey, setIsSurvey] = useState(false)
  const[isNotComplete, setIsNotComplete] = useState(false)
  const[filename, setFilename] = useState("")
  const[attach_filename1, setAttach_filename1] = useState("")
  const[attach_filename2, setAttach_filename2] = useState("")
  const[attach_filename3, setAttach_filename3] = useState("")
  const[isAllCheck, setIsAllCheck] = useState(false)
  const[isPreview, setIsPreview] = useState(false)
  const[isShowModal, setIsShowModal] = useState(false)
  const[mail_preview, setMail_preview] = useState([])
  const[isProcessing, setIsProcessing] = useState(false)
  const[isComplete, setIsComplete] = useState(false)
  const[completeMsg, setCompleteMsg] = useState("")
  const[isAlertModal, setIsAlertModal] = useState(false)
  const[alertMsg, setAlertMsg] = useState("")
  const[isCSVShowModal, setIsCSVShowModal] = useState(false)
  const[isShownSouthOrNorth, setIsShownSouthOrNorth] = useState(false)

  const send = async() => {
    setIsProcessing(true)
    // 送信
    const data = await apicall(url, "post", {
      periods_id: period_id,
      filename: filename,
      attach_filename1: attach_filename1,
      attach_filename2: attach_filename2,
      attach_filename3: attach_filename3,
      rows: listRow,
      preview: mail_preview
    })
    if (data) {
      getList(m_districts_id, isEvaluator, isSurvey, isNotComplete)
      setIsPreview(false)
      setFilename("")
      setAttach_filename1("")
      setAttach_filename2("")
      setAttach_filename3("")
      setCompleteMsg(data)
      setIsComplete(true)
    }
    setIsShowModal(false)
    setIsProcessing(false)
  }

  const isSelected = (fname, rows) => {
    var select = false
    if (fname != "") {
      rows.map((row, rowIndex) => {
        if (rows[rowIndex]["isSelect"]) {
          select = true
        }
      })
    }
    return select
  }
  const setFName = (fname) => {
    setFilename(fname)
    var pre = isSelected(fname, listRow)
    setIsPreview(pre)
  }

  const getFiles = async() => {
    const data = await apicall(url + "/files", "get")
    if (data) {
      setFileNames(data.filename)
      setAttachFileNames(data.attach_filename)
    }
  }
  const getList = async(p_m_districts_id, p_isEvaluator, p_isSurvey, p_isNotComplete) => {
    const data = await apicall(url + "/list?periods_id=" + cookiePeriods_id +
            "&m_districts_id=" + p_m_districts_id + "&isEvaluator=" + p_isEvaluator +
            "&isSurvey=" + p_isSurvey + "&isNotComplete=" + p_isNotComplete, "get")
    if (data) {
      setListRow(data)
    }

    setM_districts_id(p_m_districts_id)
    setIsEvaluator(p_isEvaluator)
    setIsSurvey(p_isSurvey)
    setIsNotComplete(p_isNotComplete)
    setIsPreview(false)
    setIsAllCheck(false)
  }
  const setAllCheck = async(checked) => {
    let r = listRow.slice(0, listRow.length)
    r.map((row) => {
        row["isSelect"] = checked
    })
    var pre = isSelected(filename, r)
    setListRow(r)
    setIsPreview(pre)
    setIsAllCheck(checked)
  }

  const setSelectPreview = (rowIndex, check) => {
    let r = listRow.slice(0, listRow.length)
    r[rowIndex]["isSelect"] = check
    var pre = isSelected(filename, r)
    setListRow(r)
    setIsPreview(pre)
  }

  const getTemplateFile = async() => {
    const data = await apicall(url + "/template", "post",{
      period_id: period_id,
      filename: filename,
      rows: listRow,
      south_or_north: southOrNorth
    })
    if (data) {
      setMail_preview(data)
    }
  }
  const mailPreview = () => {
    getTemplateFile()
    setIsShowModal(true)
  }

  useEffect(async() => {
    setPeriod_id(cookiePeriods_id)
    const data = await apicall(url_m, "get")
    if (data) {
      setDistrictsResponse(data)
    }

    getFiles()
    getList(m_districts_id, isEvaluator, isSurvey, isNotComplete)
  }, [])

  const jadgescsvimport = async() => {
    jadgecsvClose()
    const data = await apicall("judges-csv/import", "get")
    if (data) {
      if(data.result){
        setCompleteMsg(data.message)
        setIsComplete(true)
      }else{
        setAlertMsg(data.message)
        setIsAlertModal(true)
      }
    }
  }

  const clearMsg = () => {
    setCompleteMsg("")
    setIsComplete(false)
  }
  const AlertClearMsg = () => {
    setAlertMsg("")
    setIsAlertModal(false)
  }

  const jadgecsvModal = () => {
    setIsCSVShowModal(true)
  }
  const jadgecsvClose = () => {
    setIsCSVShowModal(false)
  }

  const selectDistrict = (v) => {
    getList(v, isEvaluator, isSurvey, isNotComplete)
    if (v === '3') { // 3...関東北
      setIsShownSouthOrNorth(true)
    } else {
      setIsShownSouthOrNorth(false)
      setSouthOrNorth("")
    }
  }

  return(
    <article>
      <div className="level">
      <label className="label">メール送信管理 （評価員・調査員）</label>
      </div>
      {AlertDialog(isComplete, false, completeMsg, "", clearMsg)}
      {AlertDialog(isAlertModal, true, alertMsg, "", AlertClearMsg)}
      <div>
        <div className="columns">
          <div className="column is-half">
            <table className="has-text-left">
                <tr>
                    <td width={150}><label>地区</label></td>
                    <td>
                      <select className="select" value={m_districts_id} style={{width: 100}}
                      onChange={(e) => {
                        selectDistrict(e.target.value)
                      }}>
                      <option value={""}>{""}</option>
                      {districtsResponse.map((row, rowIndex) => 
                          <option value={row["id"]}>{row["name"]}</option>
                      )}
                      </select>
                    </td>
                </tr>
                <tr className={isShownSouthOrNorth ? '' : 'is-hide'} >              
                  <td width={150}><p>関東調査員用</p><p>日程案記載地区</p></td>
                  <td colSpan="1">
                      <select className="select" value={southOrNorth}
                        onChange={(e) => setSouthOrNorth(e.target.value)}>
                        <option value={"both"}>両方</option>
                        <option value={"north"}>関東北のみ</option>
                        <option value={"south"}>関東南のみ</option>
                      </select>
                  </td>
                  <td><br /></td>
                </tr>
                <tr><td><br /></td></tr>

                <tr>
                    <td><label>評価員</label></td>
                    <td>
                        <input type="checkbox" 
                          onChange={(e) => {getList(m_districts_id, e.target.checked, isSurvey, isNotComplete)}} />
                    </td>
                    <td><label>調査員</label></td>
                    <td>
                        <input type="checkbox"
                          onChange={(e) => {getList(m_districts_id, isEvaluator, e.target.checked, isNotComplete)}} />
                    </td>
                </tr>
                <tr>
                    <td><label>未完了</label></td>
                    <td>
                      <input type="checkbox"
                          onChange={(e) => {getList(m_districts_id, isEvaluator, isSurvey, e.target.checked)}} />
                    </td>
                </tr>
                <tr><td><br/></td></tr>
                <tr>
                    <td width={150}><label>ひな形</label></td>
                    <td colSpan="3">
                        <select className="select" value={filename}
                          onChange={(e) => {setFName(e.target.value);}}>
                          <option value={""}>{""}</option>
                          {fileNames.map((val) => 
                            <option value={val}>{val}</option>
                          )}
                        </select>
                    </td>
                </tr>
                <tr><td><br/></td></tr>
                <tr>
                    <td><label>添付ファイル</label></td>
                    <td colSpan="3">
                      <select className="select" value={attach_filename1}
                          onChange={(e) => {setAttach_filename1(e.target.value)}}>
                          <option value={""}>{""}</option>
                          {attachFileNames.map((val) => 
                            <option value={val}>{val}</option>
                          )}
                        </select>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td colSpan="3">
                        <select className="select" value={attach_filename2}
                          onChange={(e) => {setAttach_filename2(e.target.value)}}>
                          <option value={""}>{""}</option>
                          {attachFileNames.map((val) => 
                            <option value={val}>{val}</option>
                          )}
                        </select>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td colSpan="3">
                        <select className="select" value={attach_filename3}
                          onChange={(e) => {setAttach_filename3(e.target.value)}}>
                          <option value={""}>{""}</option>
                          {attachFileNames.map((val) => 
                            <option value={val}>{val}</option>
                          )}
                        </select>
                    </td>
                </tr>
            </table>
          </div>
          <div className="column is-half">
            <p>評価員／調査員CSVの取込</p>
            <button className="button" onClick={() => jadgecsvModal()}>取込</button>
          </div>
        </div>
      </div>
      <div className="lebel has-text-right">
        <button className="button" disabled={ isPreview ? false : true} 
          onClick={() => mailPreview()}>送信プレビュー</button>
      </div>
      <div className="level">
        <div className="table-container-for-mail-judge">
          <table className="table is-fullwidth is-bordered has-text-left">
              <thead>
              <tr>
                  <th>
                  <input type="checkbox" checked={isAllCheck}
                      onChange={(e) => {setAllCheck(e.target.checked)}} />
                  </th>
                  <th>氏名</th>
                  <th>評価員／調査員</th>
                  <th>主担当地区</th>
                  <th>送信日時</th>
                  <th>送信時使用ひな形名</th>
                  <th>日程入力完了済み</th>
              </tr>
              </thead>
              <tbody>
                  {listRow.map((row, rowIndex) => 
                  <tr>
                      <td>
                        <input type="checkbox" checked={row["isSelect"]} 
                          onChange={(e) => {setSelectPreview(rowIndex, e.target.checked)}} />
                      </td>
                      <td>{row["name"]}</td>
                      <td>{row["evaluator_flag"] == true ? "評価員" : "調査員"}</td>
                      <td>{row["district_name"]}</td>
                      <td width={200}>{row["create_time"]}</td>
                      <td>{row["filename"]}</td>
                      <td>{row["complete_time"] != "" ? "〇": "－"}</td>
                  </tr>
                  )}
              </tbody>
          </table>
          <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
        </div>
      </div>
      {/* 送信プレビュー */}
      {MailSendPreview(isShowModal, setIsShowModal, send, mail_preview, attach_filename1, attach_filename2, attach_filename3)}
      {Processing(isProcessing)}

      {/* 評価員／調査員取込ダイアログ */}
      <div className={isCSVShowModal ? 'modal is-active' : 'modal'}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="has-text-left">評価員／調査員CSV取込</p>
            <div style={{ flexGrow: 1 }}></div>
            <button className="delete" aria-label="close" onClick={() => jadgecsvClose()}/>
          </header>
          <section className="modal-card-body">
            <p className="has-text-left">
              評価員／調査員CSVの取込を実施しますか。<br />
              先に、ACCESSから所定の場所へファイルを配置してください。
            </p>
          </section>
          <footer className="modal-card-foot">
            <button className="button is-info" onClick={() => jadgescsvimport()}>
              <p>取込</p>
            </button>
            <button className="button" onClick={() => jadgecsvClose()}>
              <span className="icon">
                <i className="fas fa-undo" />
              </span>
              <p>キャンセル</p>
            </button>
          </footer>
        </div>
      </div>
    </article>
  )
}

export default MailJudges