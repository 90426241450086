import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import apicall from '../utils/apicall'
import SpreadSheet from './spreadsheets/SpreadSheet'
import useCookie from '../utils/useCookie'
import AlertDialog from './AlertDialog'
import FactoryScheduleHelp from './FactoryScheduleHelp'
import ModalDialog from './ModalDialog'
import DownLoad from './DownLoad'

const FactorySchedules = () => {
  const url = "factory-schedules"
  const [path, setPath] = useState('')
  const location = useLocation()
  const[headers_id, setHeaders_id] = useState('')
  const[headers, setHeaders] = useState([])
  const[headerName, setHeaderName] = useState('')
  const[isShowModal, setIsShowModal] = useState(false)
  const[isShowHelp, setIsShowHelp] = useState(false)
  const[ly, setLy] = useState('')
  const[cellInfo, setCellInfo] = useState("")
  const[isComplete, setIsComplete] = useState(false)
  const[deleteRowIds, setDeleteRowIds] = useState([])
  
  const[isSaveMsg, setIsSaveMsg] = useState(false)
  const[saveMsg, setSaveMsg] = useState("")

  const[isErrorMsg, setIsErrorMsg] = useState(false)
  const[errorMsg, setErrorMsg] = useState("")

  const[isAlreadyCompleted, setIsAlreadyCompleted] = useState(false)
  const[alreadyCompletedMessage, setAlreadyCompletedMessage] = useState("")


  const [cookieRole, setCookieRole] = useCookie("role", "")
  const [cookieId, setCookieId] = useCookie("id", "")
  
  const[rows, _setRows] = useState({})
  const rowsRef = useRef(rows)
  const setRows = (rs) => {
    console.log(rs)
    rowsRef.current = rs
    _setRows(rs)
  }

  const[datasheet, _setDatasheet] = useState({})
  const datasheetRef = useRef(datasheet)
  const setDatasheet = (ds) => {
    datasheetRef.current = ds
    _setDatasheet(ds)
    syncRows(ds)
  }

  const[blankLine, _setBlankLine] = useState({})
  const blankLineRef = useRef(blankLine)
  const setBlankLine = (bl) => {
    blankLineRef.current = bl
    _setBlankLine(bl)
  }

  useEffect(() => {
    let p = queryString.parse(location.search)
    let l_path = location.pathname
    setPath(l_path)

    if (l_path.slice(1) == url) {
      var id = headers_id
      if (p.headers_id) {
        id = Number(p.headers_id)
        setHeaders_id(id)
      } else {
        throw new Error('県組合ご担当者様はメールからログインしなおしてください')
      }

      getFactorySchedules(id);
    } else {
      // 非組合入力
      getNotMemberFacotrySchedules();
    }
  }, [])

  const getFactorySchedules = async(id) => {
    var d = new Date();
    var dfmt = "&d=" + d.getFullYear() + d.getMonth()+1 + d.getDate() + d.getHours() + d.getMinutes() + d.getSeconds() + d.getMilliseconds()
    
    const response = await apicall(url + '/get-schedules?headers_id=' + id + dfmt, 'get')
    if (response) {
      setHeaders(response.headers)
      setRows(response.bodys)
      setHeaderName(response.header_name)
      initializeDatasheet(response.bodys)
      setBlankLine(response.blank_line)
      setCellInfo(response.cell_info)
      setIsComplete(response.is_complete)

      if (response.is_complete && cookieRole !== 'head') {
        setIsAlreadyCompleted(true)
        setAlreadyCompletedMessage("支部にて既に入力完了済みのため、入力内容の変更・更新はできません")
      }
    }
  }

  const getNotMemberFacotrySchedules = async() => {
    const response = await apicall(url + '/get-not-member-schedules', 'get')
    if (response) {
      setHeaders(response.headers)
      setRows(response.bodys)
      setHeaderName("非組合員工場")
      initializeDatasheet(response.bodys)
      setBlankLine(response.blank_line)
      setCellInfo(response.cell_info)
      setIsComplete(true)
    }
  }

  const initializeDatasheet = (ds_source) => {
    var sheet = {}
    Object.keys(ds_source).forEach((key, y) => {
      ds_source[key].forEach((cell, x) => {
        sheet[x+"-"+y] = cell.value
      })
    })
    setDatasheet(sheet)
  }

  const syncRows = (ds) => {
    if (!Object.keys(rowsRef.current).length) return

    let cloneRows = JSON.parse(JSON.stringify(rowsRef.current))

    var last_y = 0
    var last_rowy = Object.keys(cloneRows).length - 1

    Object.keys(ds).forEach(key => {
      let cx = key.split('-')[0]
      let cy = key.split('-')[1]

      if (cy in cloneRows) {
        cloneRows[cy][cx].value = ds[key]
      } else {
        if (cx == 0) { 
          cloneRows[cy] = [...blankLineRef.current]
        }
      }
      last_y = cy
    })

    if (last_y < last_rowy) {
      delete cloneRows[last_rowy]
    }

    setRows(cloneRows);
  }
  

  const addLine = () => {
    var sheet = {}
    var last_x = 0
    var last_y = 0

    Object.keys(datasheetRef.current).forEach(key => {
      last_x = key.split('-')[0]
      last_y = key.split('-')[1]
    })

    var added_y = parseInt(last_y) + 1
    for (let cy = 0; cy <= added_y; cy++) {
      for (let cx = 0; cx <= last_x; cx++) {
        if (cy != added_y) {
          sheet[cx + '-' + cy] = datasheetRef.current[cx + '-' + cy]
        } else {
          sheet[cx + '-' + cy] = blankLineRef.current[cx].value
        }
      }
    }
    setDatasheet(sheet)
  }

  const deleteLine = (target_y) => {
    var sheet = {}
    Object.keys(datasheetRef.current).forEach(key => {
      var current_x = key.split('-')[0]
      var current_y = key.split('-')[1]

      if (current_y < target_y) {
        sheet[key] = datasheetRef.current[key]
      } else if (current_y > target_y) {
        var new_y = current_y - 1
        sheet[current_x + '-' + new_y] = datasheetRef.current[key]
      }
    })

    var drIds = deleteRowIds
    drIds.push(datasheetRef.current[0 + '-' + target_y])
    setDeleteRowIds(drIds)
    setDatasheet(sheet)
  }

  const save = async() => {
    let convertedRows = getConvertedDsToRows()

    const data = await apicall(url + '/save', 'post', {
      headers_id: headers_id,
      headers: headers,
      bodys: convertedRows,
      cell_info: cellInfo,
      delete_row_ids: deleteRowIds
    })

    if (data.isSuccess) {
      setSaveMsg(data.message)
      setIsSaveMsg(true)
      getFactorySchedules(headers_id)
    } else {
      setErrorMsg(data.message)
      setIsErrorMsg(true)
    }
  }

  const complete = async() => {
    let convertedRows = getConvertedDsToRows()

    const data = await apicall(url + '/complete', 'post', {
      headers_id: headers_id,
      headers: headers,
      bodys: convertedRows,
      cell_info: cellInfo,
      delete_row_ids: deleteRowIds
    })
    
    if (data.isSuccess) {
      setSaveMsg(data.message)
      setIsSaveMsg(true)
      getFactorySchedules(headers_id)
    } else {
      setErrorMsg(data.message)
      setIsErrorMsg(true)
    }
    
    setIsShowModal(false)
  }

  const update = async() => {
    let convertedRows = getConvertedDsToRows()

    if (path.slice(1) == url) {
      const data = await apicall(url + "/update", 'post', {
        headers_id: headers_id,
        headers: headers,
        bodys: convertedRows,
        cell_info: cellInfo,
        delete_row_ids: deleteRowIds
      })

      if (data.isSuccess) {
        setSaveMsg(data.message)
        setIsSaveMsg(true)
        getFactorySchedules(headers_id)
      } else {
        setErrorMsg(data.message)
        setIsErrorMsg(true)
      }

    } else {
      const data = await apicall(url + "/not-member-update", 'post', {
        headers: headers,
        bodys: convertedRows,
        cell_info: cellInfo,
        delete_row_ids: deleteRowIds
      })

      if (data.isSuccess) {
        setSaveMsg(data.message)
        setIsSaveMsg(true)
        getNotMemberFacotrySchedules()
      } else {
        setErrorMsg(data.message)
        setIsErrorMsg(true)
      }
    }
  }

  const closeErrorModal = () => {
    setErrorMsg("")
    setIsErrorMsg(false)
  }

  
  const closeAlreadyCompletedModal = () => {
    setAlreadyCompletedMessage("")
    setIsAlreadyCompleted(false)
  }

  const getConvertedDsToRows = () => {
    let r = JSON.parse(JSON.stringify(rowsRef.current))
    Object.keys(r).forEach((key, y) => {
      r[key].forEach((cell, x) => {
        let value = datasheetRef.current[x + '-' + y]
        cell.value = value
      })
    })
    return r;
  }

  const setClearMsg = () => {
    setIsSaveMsg(false)
  }

  const excelOutput = async() => {
    if (!isComplete) {
      save()
    }

    // TODO url設定
    const response = 
      await apicall(url+ "/excel-output?headers_id=" + headers_id,
        "get",
        {},
        false)
    if (response) {
      let disposition = response.headers.get("content-disposition")
      DownLoad(disposition, await response.blob())
    }
  }
 
  return (
    <section>
      <label className='label'>工場スケジュール管理（{headerName}）</label>
      <br/>
      <p>
        ※ 申請工場名、申請グレード及び現グレード、住所等お間違えのない様ご入力願います。<br/>
        ※ 原則として各支部毎の重点的審査期間以内且つ１日２工場で日程調整して下さい。<br/>
        なお、２工場の組合せは、Ｒ－Ｒ、Ｒ－Ｊ、Ｊ－Ｊのグレードの組合せを優先させて下さい。
      </p>
      <br/>
      <button className="button" onClick={() => setIsShowHelp(true)}>ヘルプ</button>
      <span className="side-margin" />
      {
        headers_id
        ? <button className="button is-success" onClick={() => excelOutput()}>Excel出力</button>
        : ''
      }
      <br/>
      <br/>
      
      {AlertDialog(isSaveMsg, false, saveMsg, "", setClearMsg)}
      <ModalDialog
        isShow={isErrorMsg}
        messageAttribute={"is-danger"}
        title={"エラー"}
        message={errorMsg}
        close={closeErrorModal}
        ok={closeErrorModal} />

      <ModalDialog
        isShow={isAlreadyCompleted}
        messageAttribute={"is-info"}
        title={"完了済みです"}
        message={alreadyCompletedMessage}
        close={closeAlreadyCompletedModal}
        ok={closeAlreadyCompletedModal}
      />

      <div className="table-container-for-spreadsheet">
        
        <SpreadSheet
            headers={headers}
            rowsRef={rowsRef}
            datasheetRef={datasheetRef}
            setDatasheet={setDatasheet} 
            deleteLine={deleteLine} />
          
      </div>
      <br/>
        {cookieRole === 'head' && isComplete ? 
          <div className="level-left">
            <div className="level-item" >
              <button className="button" onClick={() => addLine()}>
                行追加
              </button>
            </div>
            <div className="level-item">
              <button className="button" onClick={() => update()}>
                更新
              </button>
            </div>
          </div>
          :
          <div className="level-left">
            <div className="level-item" >
              <button className={isComplete ? "is_none" : "button"} onClick={() => addLine()}>
                行追加
              </button>
            </div>
            <div className="level-item">
              <button className={isComplete ? "is_none" : "button"} onClick={() => save()}>
                一時保存
              </button>
            </div>
            <div className="level-item">
              <button className={isComplete || cookieRole === "prefectures" ? "is_none" : "button"} onClick={() => setIsShowModal(true)}>
                完了
              </button>
            </div>
          </div>
        }

        {/* 完了ダイアログ */}
        <div className={isShowModal ? 'modal is-active' : 'modal'}>
          <div className="modal-background" />
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="has-text-left">工場スケジュール 入力完了</p>
              <div style={{ flexGrow: 1 }}></div>
              <button className="delete" aria-label="close" onClick={() => setIsShowModal(false)} />
            </header>
            <section className="modal-card-body">
              <p className="has-text-left">【注意】<br/>
              現在、保存されている内容で、スケジュールの入力・編集を完了します。<br/>
              完了後、入力内容は全鉄評に送信されるため、編集することができなくなります。<br/>
              入力されているスケジュールに間違いのないことをご確認の上、完了ボタンをクリックしてください。
              </p>
            </section>
            <footer className="modal-card-foot">
              <button className="button is-info" onClick={() => complete()}>
                <span className="icon">
                  <i className="fas fa-info-circle" />
                </span>
                <p>完了</p>
              </button>
              <button className="button" onClick={() => setIsShowModal(false)}>
                <span className="icon">
                  <i className="fas fa-undo" />
                </span>
                <p>キャンセル</p>
              </button>
            </footer>
          </div>
        </div>

        {/* ヘルプダイアログ */}
        <div className={isShowHelp ? 'modal is-active' : 'modal'}>
          <FactoryScheduleHelp activate={setIsShowHelp} />
        </div>
        
    </section>
  );
}

export default FactorySchedules;