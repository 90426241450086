import React, {useState, useEffect} from 'react'
import apicall from '../utils/apicall'
import MailSendPreview from './MailSendPreview'
import Processing from './Processing'
import useCookie from "../utils/useCookie"
import AlertDialog from './AlertDialog'

const MailPrefectures = () => {
  const url = "mail-prefectures"
  const[cookiePeriods_id, setCookiePeriods_id] = useCookie("periods_id", "")
  const[listRow, setListRow] = useState([])
  const[attachFileNames, setAttachFileNames] = useState([])
  const[fileNames, setFileNames] = useState([])
  const[period_id, setPeriod_id] = useState("")
  const[filename, setFilename] = useState("")
  const[attach_filename1, setAttach_filename1] = useState("")
  const[attach_filename2, setAttach_filename2] = useState("")
  const[attach_filename3, setAttach_filename3] = useState("")
  const[isPreview, setIsPreview] = useState(false)
  const[isShowModal, setIsShowModal] = useState(false)
  const[mail_preview, setMail_preview] = useState([])
  const[isProcessing, setIsProcessing] = useState(false)
  const[isBranch, setIsBranch] = useState(false)
  const[isPrefecture, setIsPrefecture] = useState(false)
  const[isComplete, setIsComplete] = useState(false)
  const[completeMsg, setCompleteMsg] = useState("")

  const send = async() => {
      setIsProcessing(true)
      // 送信
      const data = await apicall(url, "post", {
        periods_id: period_id,
        filename: filename,
        attach_filename1: attach_filename1,
        attach_filename2: attach_filename2,
        attach_filename3: attach_filename3,
        rows: listRow,
        preview: mail_preview
      })
      if (data) {
        setIsPreview(false)
        getList()
        setIsBranch(false)
        setIsPrefecture(false)
        setFilename("")
        setAttach_filename1("")
        setAttach_filename2("")
        setAttach_filename3("")
        setCompleteMsg(data)
        setIsComplete(true)
      }
      setIsShowModal(false)
      setIsProcessing(false)
  }

  const isSelected = (fname, r) => {
    var select = false
    if (fname != "") {
      r.map((row, rowIndex) => {
        if (listRow[rowIndex]["isSelect"]) {
          select = true
        }
      })
    }
    return select
  }
  const setFName = (fname) => {
    var pre = isSelected(fname, listRow)
    setFilename(fname)
    setIsPreview(pre)
  }
  const setSelectPreview = (rowIndex, check) => {
      let r = listRow.slice(0, listRow.length)
      r[rowIndex]["isSelect"] = check
      var pre = isSelected(filename, r)
      setListRow(r)
      setIsPreview(pre)
  }
  const getFiles = async() => {
    const data = await apicall(url + "/files", "get")
    if (data) {
      setFileNames(data.filename)
      setAttachFileNames(data.attach_filename)
    }
  }
  const getList = async() => {
      const data = await apicall(url + "/list?periods_id=" + cookiePeriods_id, "get")
      if (data) {
        setListRow(data)
      }
  }
  const getTemplateFile = async() => {
      const data = await apicall(url + "/template", "post",{
        period_id: period_id,
        filename: filename,
        rows: listRow
      })
      if (data) {
        setMail_preview(data)
      }
  }
  const mailPreview = () => {
      getTemplateFile()
      setIsShowModal(true)
  }

  const onChangeBranch = (checked) => {
      isCheckChange(checked, isPrefecture)
      setIsBranch(checked)
  }
  const onChangePrefecture = (checked) => {
      isCheckChange(isBranch, checked)
      setIsPrefecture(checked)
  }

  const isCheckInput = (branch_flag) => {
      let all = 0
      if (isBranch != isPrefecture) {
          if (isBranch) {
            all = 1
          } else {
            all = 2
          }
      }
      let result = true
      if (branch_flag && all == 2) {
          result = false
      } else if (!branch_flag && all == 1) {
          result = false
      }
      return result
  }

  const isCheckChange = (isB, isP) => {
    let all = 0
    if (isB != isP) {
        if (isB) {
          all = 1
        } else {
          all = 2
        }
    }
    let r = listRow.slice(0, listRow.length)
    r.map((row) => {
        let flg = row["branch_flag"]
        if (flg == true && all == 2) {
            row["isSelect"] = false
        }
        if (flg == false && all == 1) {
            row["isSelect"] = false
        }
    })
    var pre = isSelected(filename, r)
    setListRow(r)
    setIsPreview(pre)
  }

  useEffect(async() => {
      setPeriod_id(cookiePeriods_id)
      getFiles()
      getList()
  }, [])

  const clearMsg = () => {
    setCompleteMsg("")
    setIsComplete(false)
  }

  return(
    <article>
      <div className="level">
      <label className="label">スケジュール入力依頼メール送信管理 （支部・県）</label>
      </div>
      {AlertDialog(isComplete, false, completeMsg, "", clearMsg)}
      <div>
          <table className="has-text-left">
              <tr>
                  <td>
                      <label>宛先</label>
                  </td>
                  <td width={60}>
                      <label>支部</label>
                  </td>
                  <td>
                      <input type="checkbox" checked={isBranch} onChange={(e) => {onChangeBranch(e.target.checked)}} />
                  </td>
              </tr>
              <tr>
                  <td></td>
                  <td>
                      <label>県組合</label>
                  </td>
                  <td>
                      <input type="checkbox" checked={isPrefecture} onChange={(e) => {onChangePrefecture(e.target.checked)}} />
                  </td>
              </tr>
              <tr><td><br/></td></tr>
              <tr>
                  <td width={150}>
                      <label>ひな形</label>
                  </td>
                  <td colSpan="2">
                      <select className="select" value={filename}
                        onChange={(e) => {setFName(e.target.value);}}>
                        <option value={""}>{""}</option>
                        {fileNames.map((val) => 
                          <option value={val}>{val}</option>
                        )}
                      </select>
                  </td>
              </tr>
              <tr><td><br/></td></tr>
              <tr>
                  <td>
                      <label>添付ファイル</label>
                  </td>
                  <td colSpan="2">
                     <select className="select" value={attach_filename1}
                        onChange={(e) => {setAttach_filename1(e.target.value)}}>
                        <option value={""}>{""}</option>
                        {attachFileNames.map((val) => 
                          <option value={val}>{val}</option>
                        )}
                      </select>
                  </td>
              </tr>
              <tr>
                  <td>
                  </td>
                  <td colSpan="2">
                      <select className="select" value={attach_filename2}
                        onChange={(e) => {setAttach_filename2(e.target.value)}}>
                        <option value={""}>{""}</option>
                        {attachFileNames.map((val) => 
                          <option value={val}>{val}</option>
                        )}
                      </select>
                  </td>
              </tr>
              <tr>
                  <td>
                  </td>
                  <td colSpan="2">
                      <select className="select" value={attach_filename3}
                        onChange={(e) => {setAttach_filename3(e.target.value)}}>
                        <option value={""}>{""}</option>
                        {attachFileNames.map((val) => 
                          <option value={val}>{val}</option>
                        )}
                      </select>
                  </td>
              </tr>
          </table>
      </div>
      <div className="lebel has-text-right">
        <button className="button" disabled={ isPreview ? false : true} onClick={() => mailPreview(true)}>送信プレビュー</button>
      </div>
      <div className="level">
        <div className=" table-container-for-mail-prefectures">
          <table className="table is-fullwidth is-bordered has-text-left">
              <thead>
              <tr>
                  <th width={10}></th>
                  <th>地区名</th>
                  <th>県組合名</th>
                  <th>支部担当</th>
                  <th>メールアドレス</th>
                  <th>送信日時</th>
              </tr>
              </thead>
              <tbody>
                  {listRow.map((row, rowIndex) => 
                  <tr>
                      <td>
                        <input type="checkbox"
                            checked={row["isSelect"]}
                            disabled={!isCheckInput(row["branch_flag"])}
                            onChange={(e) => {setSelectPreview(rowIndex, e.target.checked)}} />
                      </td>
                      <td>{row["name"]}</td>
                      <td>{row["prefecture_name"]}</td>
                      <td className="has-text-centered">{row["branch_flag"] == true ? "〇": ""}</td>
                      <td>{row["mailaddress"]}</td>
                      <td width={200}>{row["create_time"]}</td>
                  </tr>
                  )}
              </tbody>
          </table>
          <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
        </div>
      </div>
      {/* 送信プレビュー */}
      {MailSendPreview(isShowModal, setIsShowModal, send, mail_preview, attach_filename1, attach_filename2, attach_filename3)}
      {Processing(isProcessing)}
    </article>
  )
}

export default MailPrefectures