import React, {useState, useEffect} from 'react'
import apicall from '../utils/apicall'
import ModalDialog from './ModalDialog'

const ExaminationPrefectures = () => {
    const url = "examination-prefectures"
    const url_login = "login"
    const[year, setYear] = useState("")
    const[period, setPeriod] = useState(-1)
    const[yaersResponse, setYaersResponse] = useState([])
    const[periodsResponse, setperiodsResponse] = useState([])
    const[responseList, setResponseList] = useState([])

    const[isShowDialog, setIsShowDialog] = useState(false)
    const[dialogMessage, setDialogMessage] = useState("")
    const[dialogMessageAttribute, setDialogMessageAttribute] = useState("")

    useEffect(async() => {
        const response_content = await apicall(url_login + "/year", "get")
        if (response_content) {
            setYaersResponse(response_content)
        }
    }, [])

    const change_year = async(p_year) => {
        setYear(p_year)
        const response_content = await apicall(url_login + "/period?year=" + p_year, "get")
        if (response_content) {
            setperiodsResponse(response_content)
        }
        setPeriod(-1)
        setResponseList([])
    }

    const change_period = async(period_id) => {
        setPeriod(period_id)
        if (period_id < 0) {
            setResponseList([])
            return
        }
        const response_content = await apicall(url + "?periods_id=" + period_id, "get")
        if (response_content) {
            if (response_content.isSuccess) {
                setResponseList(response_content)
            }
        }
    }

    const setSelect = (rowIndex, rIndex, checked) => {
        let r = JSON.parse(JSON.stringify(responseList))
        r.rows[rowIndex].checked[rIndex] = checked
        setResponseList(r)
    }

    const save = async() => {
        const response_content = await apicall(url, "post", {
            period_id: responseList.period_id,
            examination_periods: responseList.examination_periods,
            rows: responseList.rows
        })
        if (response_content) {
            if (response_content.isSuccess) {
                setIsShowDialog(true)
                setDialogMessage(response_content.message)
                setDialogMessageAttribute("is-info")
                setResponseList(response_content)
            } else {
                setIsShowDialog(true)
                setDialogMessage(response_content.message)
                setDialogMessageAttribute("is-danger")
            }
        }
    }

    const closeDialog = () => {
        setIsShowDialog(false)
        setDialogMessage("")
        setDialogMessageAttribute("")
    }

    return(
        <article>
            <ModalDialog isShow={isShowDialog} message={dialogMessage} messageAttribute={dialogMessageAttribute} title={""} ok={closeDialog} close={closeDialog} />
            <div className="level">
            <label className="title">各県審査期間設定</label>
            </div>
            <div><br/></div>
            <table className="has-text-left">
                <tr>
                    <td width={100}><label>年度</label></td>
                    <td>
                    <select className="select"
                        style={{width: 100}}
                        value={year} 
                        onChange={(e) => {change_year(e.target.value)}}>
                            <option value={""}>{""}</option>
                            {yaersResponse.map((row, rowIndex) => 
                                <option value={row["code"]}>{row["name"]}</option>
                            )}
                    </select>
                    </td>
                </tr>
                <tr><td><br/></td></tr>
                <tr>
                    <td><label>期</label></td>
                    <td>
                        <select className="select"
                            style={{width: 100}}
                            value={period}
                            onChange={(e) => {change_period(e.target.value)}}>
                                <option value={-1}>{""}</option>
                                {periodsResponse.map((row, rowIndex) => 
                                    <option value={row["id"]}>{row["name"]}</option>
                                )}
                        </select>
                    </td>
                </tr>
            </table>
            <br />
            <div className="table-container-for-examination-prefectures">
            {responseList.rows !== undefined ?
                <table className="table is-bordered has-text-left">
                    <thead>
                        <tr>
                            <th className={"medium"}>地区名</th>
                            <th className={"medium"}>県名</th>
                            {responseList.examination_periods.map((row) =>
                                <th className={"slim"}>{row.name}</th>)
                            }
                        </tr>
                    </thead>
                    <tbody>
                            {responseList.rows.map((row, rowIndex) => 
                            <tr className={Number(row.m_districts_id) % 2 === 0 ? "even" : "odd"}>
                                <td>{row.district_name}</td>
                                <td>{row.prefecture_name}</td>
                                {row.checked.map((r, rIndex) =>
                                    <td>
                                        <input type="checkbox" checked={r} onChange={(e) => {setSelect(rowIndex, rIndex, e.target.checked)}} />
                                    </td>
                                )}
                            </tr>
                            )}
                    </tbody>
                </table>
                : <br/>
            }
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            </div>
            <div>
                <br/>
                {responseList.rows !== undefined ?
                    <td className="level">
                        <button className="button is-info" onClick={() => save()}>　保存　</button>
                    </td>
                    :<br/>
                }
            </div>
        </article>
    )
}
export default ExaminationPrefectures