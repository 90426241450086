import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import GetRequestTest from './components/GetRequestTest';
import PostRequestTest from './components/PostRequestTest';
import GetRequestPeriodList from './components/GetRequestPeriodList';
import PeriodSetting  from './components/PeriodSetting';
import FactorySchedules from './components/FactorySchedules';
import FactorySchedulesInquiry from  './components/FactorySchedulesInquiry';
import Login from  './components/Login';
import Logout from  './components/Logout';
import OneTimePassword from  './components/OneTimePassword';
import ScheduleOutputs from  './components/ScheduleOutputs';
import JudgeSchedule from  './components/JudgeSchedule';
import JudgeSchedulesInquiry from  './components/JudgeSchedulesInquiry';
import MailPrefectures from  './components/MailPrefectures';
import MailJudges from  './components/MailJudges';
import ExaminationSchedules from  './components/ExaminationSchedules';
import ExaminationPrefectures from  './components/ExaminationPrefectures';

import SpreadSheetDemo from './components/SpreadSheetDemo'
import useCookie from "./utils/useCookie";


import './App.css';

function App() {
  const [cookieRole, setCookieRole] = useCookie("role", "");
  const [cookiePeriod, setCookiePeriod] = useCookie("period", "");
  return (
    <div className="App">
      <Router>
        <header className="header">
          <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
              <div className="navbar-item">
                <h1 className="title">全国鉄骨評価機構　審査管理システム</h1>
              </div>
              <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </a>
            </div>
            <div className="navbar-menu" id="navbarLogout">
              <div className="navbar-end">
                <div className="navbar-item">
                  <p className="navbar-item">{cookiePeriod.length > 0 ? "ログイン中：" + cookiePeriod : ""}</p>
                  <a className="navbar-item" href="/logout">ログアウト</a>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <section className={(cookieRole == "head") ? "section-duo" : "section"}>
          <Route exact path='/'>
            {(cookieRole == "head") ? <div className="section-left"><Navbar /></div> : ""}
          </Route>
          <Route path='/period-setting'>
            {(cookieRole == "head") ? <div className="section-left"><Navbar /></div> : ""}
          </Route>
          <Route path='/examination-prefectures'>
            {(cookieRole == "head") ? <div className="section-left"><Navbar /></div> : ""}
          </Route>
          <Route path='/schedule-outputs'>
            {(cookieRole == "head") ? <div className="section-left"><Navbar /></div> : ""}
          </Route>
          <Route path='/judge-schedule'>
            {(cookieRole == "head") ? <div className="section-left"><Navbar /></div> : ""}
          </Route>
          <Route path='/judge-schedules-inquiry'>
            {(cookieRole == "head") ? <div className="section-left"><Navbar /></div> : ""}
          </Route>
          <Route path='/factory-schedules'>
            {(cookieRole == "head") ? <div className="section-left"><Navbar /></div> : ""}
          </Route>
          <Route path='/not-member-factory-schedules'>
            {(cookieRole == "head") ? <div className="section-left"><Navbar /></div> : ""}
          </Route>
          <Route path='/factory-schedules-inquiry'>
            {(cookieRole == "head") ? <div className="section-left"><Navbar /></div> : ""}
          </Route>
          <Route path='/mail-prefectures'>
            {(cookieRole == "head") ? <div className="section-left"><Navbar /></div> : ""}
          </Route>
          <Route path='/mail-judges'>
            {(cookieRole == "head") ? <div className="section-left"><Navbar /></div> : ""}
          </Route>
          <Route path='/examination-schedules'>
            {(cookieRole == "head") ? <div className="section-left"><Navbar /></div> : ""}
          </Route>
          <div className={cookieRole != "head" ? "container is-fluid" : "section-right"}>
            <Route exact path='/' component={Home}/>
            {/* <Route path='/get-test' render={ () => <GetRequestTest /> }/> */}
            {/* <Route path='/post-test' render={ () => <PostRequestTest />}/> */}
            {/* <Route path='/get-test2' render={ () => <GetRequestPeriodList />}/> */}
            <Route path='/period-setting' render={ () => <PeriodSetting />}/>

            {/* <Route path='/spreadsheet-demo' render={ () => <SpreadSheetDemo />}/> */}

            <Route path='/factory-schedules' render={ () => <FactorySchedules />}/>
            <Route path='/not-member-factory-schedules' render={ () => <FactorySchedules />}/>

            <Route path='/judge-schedules-inquiry' render={ () => <JudgeSchedulesInquiry />}/>
            <Route path='/judge-schedule' render={ () => <JudgeSchedule />}/>
            <Route path='/login' render={ () => <Login />}/>
            <Route path='/logout' render={ () => <Logout />}/>
            <Route path='/one-time-password' render={ () => <OneTimePassword />}/>
            <Route path='/schedule-outputs' render={ () => <ScheduleOutputs />}/>
            <Route path='/mail-prefectures' render={ () => <MailPrefectures />}/>
            <Route path='/mail-judges' render={ () => <MailJudges />}/>
            <Route path='/factory-schedules-inquiry' render={ () => <FactorySchedulesInquiry />}/>
            <Route path='/examination-schedules' render={ () => <ExaminationSchedules />}/>
            <Route path='/examination-prefectures' render={ () => <ExaminationPrefectures />}/>
            
          </div>
        </section>
      </Router>
    </div>
  );
}

export default App;
