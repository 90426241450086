import React from 'react'

const Home = () => {
  return (
    <div>
      <p>Home</p>
      <canvas className="my-canvas">
        <button onclick={() => alert("test")}>button</button>
      </canvas>
    </div>
  )
}
export default Home;