// local
// const api_server_domain = 'http://localhost:8080/jsao-schedule/v1/'
// const client_domain = 'http://localhost:3000/'

// staging
// const api_server_domain = 'http://153.122.102.224/jsao-schedule/v1/'
// const client_domain = 'http://153.122.102.224/'

// production
const api_server_domain = 'https://www.zentetsuhyo.com/jsao-schedule/v1/'
const client_domain = 'https://www.zentetsuhyo.com/'

/**
 * フロントエンドアプリケーション用定数
 * build後, クライアント側に配信する内容なので, セキュリティに関する情報等は載せない.
 */
export const app_consts = {
  api_server_domain: api_server_domain,
  client_domain: client_domain
};