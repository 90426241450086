import React, {useState, useEffect, useRef} from 'react';

const UnCopiableCell = (props) => {
  const cellRef = useRef()
  const documentClickHandler = useRef()
  const documentMouseUpHandler = useRef()

  useEffect(() => {
    documentClickHandler.current = e => {
      if (cellRef.current.contains(e.target)) return

      removeDocumentClickHandler()
    }

    documentMouseUpHandler.current = e => {
      if (cellRef.current.contains(e.target)) return
      props.setIsSelecting(false)
      removeDocumentMouseUpHandler()
    }

  }, [])

  const removeDocumentClickHandler = () => {
    document.removeEventListener('click', documentClickHandler.current)
  }

  const removeDocumentMouseUpHandler = () => {
    document.removeEventListener('mouseup', documentMouseUpHandler.current)
  }


  return (
    <td className={
      props.cell.options.cell_info === '5' ? 'is-sameday'
      : props.cell.options.cell_info === '4' ? 'is-gray'
      : props.cell.options.cell_info === '3' ? 'is-black'
      : props.cell.options.cell_info === '2' ? 'is-sunday'
      : props.cell.options.cell_info === '1' ? 'is-saturday' : ''}
      ref={cellRef}>

      <span className="icon-text-no-wrap">
        <span>{props.y + 1}</span>
        <span className="icon" onClick={() => props.deleteLine(props.y)}>
          <i className="fas fa-trash-alt" />
        </span>
      </span>
    
    </td>
  )
};

export default UnCopiableCell;