import React, {useState, useEffect, useRef} from 'react'
import { useLocation } from 'react-router-dom'
import apicall from '../utils/apicall'
import queryString from 'query-string';
import SpreadSheet from './spreadsheets/SpreadSheet'
import AlertDialog from './AlertDialog'
import JudgeScheduleHelp from './JudgeScheduleHelp'
import ModalDialog from './ModalDialog'
import DownLoad from './DownLoad'

const JudgeSchedule = () => {
    const location = useLocation()
    const url = "judge-schedule"
    const[requestResult, setRequestResult] = useState("")
    const[period_id, setPeriod_id] = useState("")
    const[judge_schedules_id, setJudge_schedules_id] = useState(3)
    const[m_judges_id, setM_judges_id] = useState("")
    const[nameRes, setNameRes] = useState("")
    const[districtsNameRes, setDistrictsNameRes] = useState("")
    const[isInquiry, setIsInquiry] =  useState(false)

    const[remark1, setRemark1] = useState("")
    const[remark2, setRemark2] = useState("")
    const[schedule_period, setSchedule_period] = useState("")
    const[mark, setMark] = useState("")
    const[completeTime, setCompleteTime] = useState("")
    const[isComplete, setIsComplete] = useState(false)
    const[updateTime, setUpdateTime] = useState("")

    const[isShowModal, setIsShowModal] = useState(false)

    const[isShowHelp, setIsShowHelp] = useState(false)
    const[isSaveMsg, setIsSaveMsg] = useState(false)
    const[saveMsg, setSaveMsg] = useState("")

    const[isErrorMsg, setIsErrorMsg] = useState(false)
    const[errorMsg, setErrorMsg] = useState("")

    const[headers, setHeaders] = useState([])
    const[cell_info, setCell_info] = useState()
    
    const[isError, setIsError] = useState(false)

    const[rows, _setRows] = useState({})
    const rowsRef = useRef(rows)
    const setRows = (rs) => {
      console.log(rs)
      rowsRef.current = rs
      _setRows(rs)
    }

    const [datasheet, _setDatasheet] = useState({})
    const datasheetRef = useRef(datasheet)
    const setDatasheet = (ds) => {
      datasheetRef.current = ds
      _setDatasheet(ds)
      syncRows(ds)
    }
    
    useEffect(() => {
      var p = queryString.parse(location.search)
      var id = judge_schedules_id
      if (p.judge_schedules_id) {
        id = Number(p.judge_schedules_id)
        setJudge_schedules_id(id)
      } else {
        throw new Error('評価員・調査員の方はメールからログインしなおしてください。')
      }
      if (p.isInquiry) {
        setIsInquiry(p.isInquiry == "true")
      } else {
        setIsInquiry(false)
      }
      get_request_judgeSchedule(id);
    }, [])

    const syncRows = (ds) => {
      if (!Object.keys(rowsRef.current).length) return
  
      let cloneRows = JSON.parse(JSON.stringify(rowsRef.current))
  
      Object.keys(ds).forEach(key => {
        let cx = key.split('-')[0]
        let cy = key.split('-')[1]
  
        if (cy in cloneRows) {
          cloneRows[cy][cx].value = ds[key]
        } 
      })
  
      setRows(cloneRows);
    }
    

    const initializeDatasheet = (ds_source) => {
      var sheet = {}
      Object.keys(ds_source).forEach((key, y) => {
        ds_source[key].forEach((cell, x) => {
          sheet[x+"-"+y] = cell.value
        })
      })
      setDatasheet(sheet)
    }
  


    const get_request_judgeSchedule = async(id) => {
      var d = new Date();
      var dfmt = "&d=" + d.getFullYear() + d.getMonth()+1 + d.getDate() + d.getHours() + d.getMinutes() + d.getSeconds() + d.getMilliseconds()
      const response = await apicall(url + "/id?judge_schedules_id=" + id + dfmt, "get")
      if (response) {
        setHeaders(response.headers)
        console.log(response.bodys)
        setRows(response.bodys)

        setPeriod_id(response.periods_id);
        setJudge_schedules_id(response.judge_schedules_id);
        setM_judges_id(response.m_judges_id);
        setNameRes(response.name);
        setDistrictsNameRes(response.districts_name);
        setRemark1(response.remarks1);
        setRemark2(response.remarks2);
        setSchedule_period(response.schedule_period);
        setCompleteTime(response.complete_time);
        setIsComplete((response.complete_time != ""));
        setUpdateTime(response.update_time);

        initializeDatasheet(response.bodys)
        setCell_info(response.cell_info)
      }
    }

    const savejudgeSchedule = async(p_url, isComplete) => {
        let save_rows = updateRows()

        // 一時保存or完了
        const data = await apicall(p_url, "post", {
          periods_id: period_id,
          judge_schedules_id:judge_schedules_id,
          m_judges_id: m_judges_id,
          name: nameRes,
          districts_name:districtsNameRes,
          remarks1: remark1,
          remarks2: remark2,
          update_time: updateTime,
          complete_time: completeTime,
          headers: headers,
          bodys: save_rows,
          cell_info: cell_info
        })
        if (data.isSuccess) {
          setRequestResult(data);
          setSaveMsg(data.message)
          // if (isComplete) {
          //   setSaveMsg("スケジュール入力を完了しました")
          // } else {
          //   setSaveMsg("スケジュールを保存しました")
          // }
          setIsSaveMsg(true)
          get_request_judgeSchedule(judge_schedules_id);
        } else {
          setErrorMsg(data.message)
          setIsErrorMsg(true)
        }

        // ie対応
        if (window.navigator.userAgent.toLowerCase() == "msie") {
          window.location.reload(true)
        }
    }

    const updateRows = () => {
      let r = JSON.parse(JSON.stringify(rowsRef.current))
      Object.keys(r).forEach((key, y) => {
        r[key].forEach((cell, x) => {
          let value = datasheetRef.current[x + '-' + y]
          cell.value = value
        })
      })
      return r;
    }

    const isErrorComplete = () => {
      let isErr = false
      Object.keys(rowsRef.current).forEach((key, y) => {
        rowsRef.current[key].forEach((cell, x) => {
          let value = datasheetRef.current[x + '-' + y]
          var info = cell.options['cell_info'];
          if (info !== '3') {
            if (value === undefined || value === '') {
              isErr = true
            }
          }
        })
      })
      if (isErr) {
        setIsError(true)
        return true
      }
      return false;
    }
    const checkComplete = () => {
      if (isErrorComplete()) {
        return
      }
      setIsShowModal(true)
    }
    const clearError = () => {
      setIsError(false)
    }
    const confirmComplete = () => {
      savejudgeSchedule(url + "/complete", true)
      setIsShowModal(false)
    }
    const setClearMsg = () => {
      setIsSaveMsg(false)
    }

    const setSpcSchedule = () => {
      if (mark == "") {
          return
      }
      let r = JSON.parse(JSON.stringify(rowsRef.current))
      var sheet = {}
      Object.keys(r).forEach((key, y) => {
        r[key].forEach((cell, x) => {
          var info = cell.options["cell_info"];
          if (info !== '3') {
            if (datasheetRef.current[x + '-' + y] === undefined || datasheetRef.current[x + '-' + y] === "") {
              sheet[x + '-' + y] = mark
            } else {
              sheet[x + '-' + y] = datasheetRef.current[x + '-' + y]
            }
          } else {
            sheet[x + '-' + y] = datasheetRef.current[x + '-' + y]
          }
        })
      })
      setDatasheet(sheet)
    }
    const setAllSchedule = () => {
      if (mark == "") {
          return
      }
      let r = JSON.parse(JSON.stringify(rowsRef.current))
      var sheet = {}
      Object.keys(r).forEach((key, y) => {
        r[key].forEach((cell, x) => {
          var info = cell.options["cell_info"];
          if (info !== '3') {
            sheet[x + '-' + y] = mark
          } else {
            sheet[x + '-' + y] = datasheetRef.current[x + '-' + y]
          }
        })
      })
      setDatasheet(sheet)
    }
    const setClearSchedule = () => {
      let r = JSON.parse(JSON.stringify(rowsRef.current))
      var sheet = {}
      Object.keys(r).forEach((key, y) => {
        r[key].forEach((cell, x) => {
          sheet[x + '-' + y] = ""
        })
      })
      setDatasheet(sheet)
    }

    const closeErrorModal = () => {
      setErrorMsg("")
      setIsErrorMsg(false)
    }

    const excelOutput = async() => {
      if (!isComplete) {
        savejudgeSchedule(url + "/save", false)
      }

      // TODO url設定
      const response = 
        await apicall(url+ "/excel-output?periods_id=" + period_id + "&judge_schedules_id=" + judge_schedules_id,
          "get",
          {},
          false)
      if (response) {
        let disposition = response.headers.get("content-disposition")
        DownLoad(disposition, await response.blob())
      }
    }


  return(
    <article>
      <div>
      <p>{/* requestResult */}</p>
      </div>
      <div className="level">
          <table className=" has-text-left ">
            <tr>
              <td colSpan="2"><label className="label">評価員・調査員スケジュール入力</label></td>
              <td>
                <button className="button" onClick={() => setIsShowHelp(true)}>ヘルプ</button>
              </td>
              <td>
                <span className="side-margin" />
                <button className="button is-success" onClick={() => excelOutput()}>Excel出力</button>
              </td>
            </tr>
            <tr>
              <td width={200}><label>氏名</label></td>
              <td>{nameRes}</td>
          </tr>
            <tr>
              <td><label>地区名</label></td>
              <td>{districtsNameRes}</td>
            </tr>
            <tr>
              <td><label>備考１</label></td>
              <td width="500">
                <input className="input" value={remark1} type="text" onChange={(e) => {setRemark1(e.target.value)}}/>
              </td>
            </tr>
            <tr>
              <td><label>備考２</label></td>
              <td>
                <input className="input" value={remark2} type="text" onChange={(e) => {setRemark2(e.target.value)}}/>
              </td>
            </tr>
          </table>
      </div>
      {AlertDialog(isSaveMsg, false, saveMsg, "", setClearMsg)}
      {AlertDialog(isError, true, "スケジュールが入力されていない日程があります", "", clearError)}
      <ModalDialog
        isShow={isErrorMsg}
        messageAttribute={"is-danger"}
        title={"エラー"}
        message={errorMsg}
        close={closeErrorModal}
        ok={closeErrorModal} />

      <div className="has-text-left" style={{ display: isComplete ? 'none' : '' }}>
        <select className="select" onChange={(e) => setMark(e.target.value)}>
            <option value=""></option>
            <option value="〇">〇にする</option>
            <option value="×">×にする</option>
            <option value="△">△にする</option>
            <option value="AM">AMにする</option>
            <option value="PM">PMにする</option>
        </select>
        &nbsp;&nbsp;
        <button className="button" onClick={() => setAllSchedule()}>全日程を一括更新</button>
        <button className="button" onClick={() => setSpcSchedule()}>空欄を一括更新</button>
        <button className="button" onClick={() => setClearSchedule()}>全てクリア</button>
      </div>

      <br/>
      <div style={{ display: isComplete ? 'none' : '' }}>
        <p>{schedule_period ? "※ " + schedule_period : ""}</p>
      </div>
      
      <div className="table-container">
        <br/>
          <SpreadSheet
            headers={headers}
            rowsRef={rowsRef}
            datasheetRef={datasheetRef}
            setDatasheet={setDatasheet}/>
        <br/>
      </div>
      <div style={{ display: isComplete ? 'none' : '' }}>
        <table>
            <tr>
                <td>
                <button className="button" onClick={() => savejudgeSchedule(url + "/save", false)}>
                  一時保存
                </button>
                </td>
                <td width={100}></td>
                <td>
                <button className="button" onClick={() => checkComplete()}>　完了　</button>
                </td>
            </tr>
        </table>

      </div>
      
      {/* 完了ダイアログ */}
      <div className={isShowModal ? 'modal is-active' : 'modal'}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="has-text-left">評価員・調査員スケジュール 入力完了</p>
            <div style={{ flexGrow: 1 }}></div>
            <button className="delete" aria-label="close" onClick={() => setIsShowModal(false)} />
          </header>
          <section className="modal-card-body">
            <p className="has-text-left">【注意】<br/>
            現在、保存されている内容で、スケジュールの入力・編集を完了します。<br/>
            完了後、入力内容は全鉄評に送信されるため、編集することができなくなります。<br/>
            入力されているスケジュールに間違いのないことをご確認の上、完了ボタンをクリックしてください。
            </p>
          </section>
          <footer className="modal-card-foot">
            <button className="button is-info" onClick={() => confirmComplete()}>
              <span className="icon">
                <i className="fas fa-info-circle" />
              </span>
              <p>完了</p>
            </button>
            <button className="button" onClick={() => setIsShowModal(false)}>
              <span className="icon">
                <i className="fas fa-undo" />
              </span>
              <p>キャンセル</p>
            </button>
          </footer>
        </div>
      </div>

      {/* ヘルプダイアログ */}
      <div className={isShowHelp ? 'modal is-active' : 'modal'}>
        {JudgeScheduleHelp(setIsShowHelp)}
      </div>
    </article>
  )
}

export default JudgeSchedule