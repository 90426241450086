import {fetch} from 'whatwg-fetch'
import {app_consts} from '../resources/app_consts'

/**
 * サーバーへのリクエストを行い, その結果のjsonを返します.
 * 
 * @param {*} endpoint リクエスト先のエンドポイント(ドメイン以下)
 * @param {string} request_method get,post,putのいずれか.空白の場合はgetになります.
 * @param {*} request_body post,putの場合はbodyをjson形式で指定してください.
 * @param {*} is_json 結果をjson形式で取得する場合true / responseを取得する場合false.
 * @returns 結果のjson / response
 */
const apicall = async(endpoint, request_method, request_body, is_json) => {

  // 開発時認証をスルーさせる場合は,ここから ---↓
  // TODO 認証切れの場合の処理.
  if (document.cookie.split(';').filter((item) => item.trim().startsWith('JSESSIONID=')).length) {
    alert("接続切れです。組合員の方、もしくは評価員・調査員の方はメールからログインしなおしてください。")
    throw document.location.href = app_consts.client_domain + "/login"
  }
  // ↑--- ここまでの処理をコメントアウトする.

  if (request_method === "get") {
    var options = {
      credentials: "include",
      method: request_method
    }
  } else if (request_method === "post" || request_method === "put") {
    if (!request_body) {
      throw new Error('リクエストメソッドにpost/putを指定した場合は, リクエストボディを指定してください.');
    }

    options = {
      credentials: "include",
      method: request_method,
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify(request_body)
    }
  } else {
    // get,post,put以外のメソッドは非対応.
    throw new Error(
      `リクエストメソッドに${request_method}が指定されています.
      リクエストメソッドはget, post, putのみ対応しています.
      また, リクエストメソッドはすべて小文字で記述して呼び出してください.`)
  }

  const request = () => fetch(
    app_consts.api_server_domain + endpoint, options
  ).then((response) => {
    if (response.ok) {
      return response
    } else {
      if(response.status=='401'){
        // throw document.location.href = app_consts.client_domain + "/login"
        alert("ログアウトしました。再度ログインしてください。")
      }else{
        alert("エラーが発生しました："+response.status)
      }
    }
  })

  const response = await request()
  if (is_json !== undefined) {
    if (is_json == false) {
      return await response
    }
  }
  if (response) {
    return await response.json()
  }
  
};

export default apicall;