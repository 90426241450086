import React from 'react'
import { Link } from 'react-router-dom'
import useCookie from "../utils/useCookie"

function Navbar() {
  const [cookieRole, setCookieRole] = useCookie("role", "");

  return(
    <aside className="menu">
      <p className="menu-label">メニュー</p>
      <ul className="menu-list">
        {/* <li><Link to="/">ホーム</Link></li> */}
        {/* <li><Link to="/get-test">GETリクエストテスト</Link></li> */}
        {/* <li><Link to="/post-test">POSTリクエストテスト</Link></li> */}
        {/* {cookieRole == 'head' ? <li><Link to="/spreadsheet-demo">スプレッドシート組み込みデモ</Link></li> : ''} */}
        
        {/* <li><Link to="/get-test2">GET業務期間テスト</Link></li> */}
        {/* {cookieRole == 'head' ? <li><Link to="/login">ログイン</Link></li> : ''} */}
        {/* {cookieRole == 'head' ? <li><Link to="/one-time-password">ワンタイム</Link></li> : ''} */}
        {cookieRole == 'head' ? <li><Link to="/period-setting">業務期間設定</Link></li> : ''}
        {cookieRole == 'head' ? <li><Link to="/examination-prefectures">各県審査期間設定</Link></li> : ''}
        {cookieRole == 'head' ? <li><Link to="/mail-prefectures">スケジュール入力依頼メール送信管理 （支部・県）</Link></li> : ''}
        {cookieRole == 'head' ? <li><Link to="/mail-judges">メール送信管理 （評価員・調査員）</Link></li> : ''}
        {cookieRole == 'head' ? <li><Link to="/not-member-factory-schedules">非組合工場審査希望スケジュール入力</Link></li> : ''}
        {cookieRole == 'head' || cookieRole == 'districts' ? <li><Link to="/factory-schedules-inquiry">工場スケジュール管理 入力状況照会</Link></li> : ''}
        {/* {cookieRole == 'head' ? <li><Link to="/judge-schedule">評価員・調査員スケジュール入力</Link></li> : ''} */}
        {cookieRole == 'head' ? <li><Link to="/judge-schedules-inquiry">評価員・調査員スケジュール入力状況照会</Link></li> : ''}
        {cookieRole == 'head' ? <li><Link to="/schedule-outputs">希望スケジュール出力機能</Link></li> : ''}
        {cookieRole == 'head' ? <li><Link to="/examination-schedules">工場審査スケジュール入力</Link></li> : ''}
        
      </ul>
    </aside>
  )
}

export default Navbar;