import React, {useState, useEffect} from 'react'
import apicall from '../utils/apicall'
import { Link } from 'react-router-dom'
import useCookie from "../utils/useCookie"

const FactorySchedulesInquiry = () => {
    const [cookiePeriods_id, setCookiePeriods_id] = useCookie("periods_id", "")
    const url = "factory-schedules-inquiry"
    const[inquiryResponse, setInquiryResponse] = useState([])
    const[period_id, setPeriod_id] = useState("4")

    const[isShowModal, setIsShowModal] = useState(false)
    const[headers_id, setHeaders_id] = useState("")
    const[district_name, setDistrict_name] = useState("")

    const get_request_FactorySchedules = async(pid) => {
        const response_content = await apicall(url + "?periods_id=" + pid, "get")
        if (response_content) {
            setInquiryResponse(response_content)
        }
    }

    const complete_cancel = async(id) => {
        // 完了取り消し
        const response_content = await apicall(url, "post", {id:id})
        if (response_content) {
          get_request_FactorySchedules(period_id)
        }
    }

    useEffect(async() => {
        var p_id = period_id
        if (cookiePeriods_id) {
            setPeriod_id(cookiePeriods_id)
            p_id = cookiePeriods_id
        }
        get_request_FactorySchedules(p_id)
    }, [])

    const confirmShow = (id, name) => {
        setHeaders_id(id)
        setDistrict_name(name)
        setIsShowModal(true)
    }
    const confirmClose = () => {
        setHeaders_id("")
        setDistrict_name("")
        setIsShowModal(false)
    }
    const confirmExecute = () => {
        complete_cancel(headers_id)
        confirmClose()
    }
    
  return(
    <article>
      <div className="level">
      <label className="label">工場スケジュール管理 入力状況照会</label>
      </div>
      <table className="table is-fullwidth is-bordered has-text-left">
        <thead>
          <tr>
            <th>支部名</th>
            <th>最終保存日</th>
            <th colSpan={3}>入力完了日時</th>
          </tr>
        </thead>
        <tbody>
            {inquiryResponse.map((row, rowIndex) => 
              <tr>
                  <td>{row["name"]}</td>
                  <td width={200}>{row["update_time"]}</td>
                  <td width={200}>{row["complete_time"]}</td>
                  <td>
                    <button className="button is-info">
                      <Link className="button is-info" to={"/factory-schedules?headers_id=" + row["id"]}>入力内容照会</Link>
                    </button>
                  </td>
                  <td>
                    <button disabled={row["complete_time"] != "" ? false : true}
                      className="button is-danger"
                      onClick={() => confirmShow(row["id"], row["name"])}>
                      完了取り消し
                    </button>
                  </td>
              </tr>
            )}
        </tbody>
      </table>
      {/* 確認ダイアログ */}
      <div className={isShowModal ? 'modal is-active' : 'modal'}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="has-text-left">（{district_name} 支部）工場スケジュール 完了取り消し</p>
            <div style={{ flexGrow: 1 }}></div>
            <button className="delete" aria-label="close" onClick={() => confirmClose()} />
          </header>
          <section className="modal-card-body">
            <p className="has-text-left">【注意】<br/>
            {district_name}支部の工場スケジュール入力について、完了取り消しを行います。</p>
            <p className="has-background-danger has-text-warning">この操作を実行すると、工場審査スケジュールが初期化されます！</p>
            <p>取り消し後は、各支部・県組合で再度入力内容を編集の上、改めて完了処理を行う必要があります。</p>
          </section>
          <footer className="modal-card-foot">
            <button className="button is-danger" onClick={() => confirmExecute()}>
              <span className="icon">
                <i className="fas fa-exclamation-triangle" />
              </span>
              <p>完了取り消し</p>
            </button>
            <button className="button" onClick={() => confirmClose()}>
              <span className="icon">
                <i className="fas fa-undo" />
              </span>
              <p>キャンセル</p>
            </button>
          </footer>
        </div>
      </div>
    </article>
  )
}

export default FactorySchedulesInquiry


