import React, {useState, useEffect} from 'react'
import apicall from '../utils/apicall'
import { Link, useLocation } from 'react-router-dom'
import queryString from 'query-string';
import useCookie from "../utils/useCookie";

const JudgeSchedulesInquiry = () => {
    const location = useLocation()
    const url = "judge-schedules-inquiry"
    const url_m = "m-districts-list"
    const[cookiePeriods_id, setCookiePeriods_id] = useCookie("periods_id", "")
    const[inquiryResponse, setInquiryResponse] = useState([])
    const[districtsResponse, setDistrictsResponse] = useState([])
    const[period_id, setPeriod_id] = useState("3")
    const[m_districts_id, setM_districts_id] = useState("")
    const[isEvaluator, setIsEvaluator] = useState(false)
    const[isSurvey, setIsSurvey] = useState(false)
    const[isNotComplete, setIsNotComplete] = useState(false)
    const[isShowModal, setIsShowModal] = useState(false)
    const[judge_schedules_id, setJudge_schedules_id] = useState("")
    const[judge_name, setJudge_name] = useState("")

    const get_request_PeriodList = async(pid, p_m_districts_id, p_isEvaluator, p_isSurvey, p_isNotComplete) => {
        const data = await apicall(url + "?periods_id=" + pid + "&m_districts_id=" + p_m_districts_id +
            "&isEvaluator=" + p_isEvaluator + "&isSurvey=" + p_isSurvey + "&isNotComplete=" + p_isNotComplete,
            "get")
        if (data) {
          setInquiryResponse(data)
        }

        setM_districts_id(p_m_districts_id)
        setIsEvaluator(p_isEvaluator)
        setIsSurvey(p_isSurvey)
        setIsNotComplete(p_isNotComplete)
    }

    const complete_cancel = async(judge_schedules_id) => {
        // 完了取り消し
        const data = await apicall(url, "post" ,{judge_schedules_id: judge_schedules_id})
        if (data) {
          if (data == "OK") {
            get_request_PeriodList(period_id, m_districts_id, isEvaluator, isSurvey, isNotComplete)
          }
        }
    }

    useEffect(async() => {
        const data = await apicall(url_m, "get")
        if (data) {
          setDistrictsResponse(data)
        }

         var p = queryString.parse(location.search)
        // var p_id = period_id
        // var d_id = m_districts_id
        // if (p.period_id) {
        //     setPeriod_id(p.period_id)
        //     p_id = p.period_id
        // }
        var p_id = period_id
        var d_id = m_districts_id
        if (cookiePeriods_id) {
            setPeriod_id(cookiePeriods_id)
            p_id = cookiePeriods_id
        }
        if (p.district_id) {
          setM_districts_id(p.district_id)
          d_id = p.district_id
        }
        get_request_PeriodList(p_id, d_id, false, false, false)
    }, [])

    const confirmShow = (judge_schedules_id, judge_name) => {
        setJudge_schedules_id(judge_schedules_id)
        setJudge_name(judge_name)
        setIsShowModal(true)
    }
    const confirmClose = () => {
        setJudge_schedules_id("")
        setJudge_name("")
        setIsShowModal(false)
    }
    const confirmExecute = () => {
        complete_cancel(judge_schedules_id)
        confirmClose()
    }
    
  return(
    <article>
      <div className="level">
      <label className="label">評価員・調査員スケジュール入力状況照会</label>
      </div>
      <div>
          <table className="has-text-left">
              <tr>
                  <td width={150}><label>地区</label></td>
                  <td>
                      <select className="select" style={{width: 100}} value={m_districts_id}
                        onChange={(e) => {get_request_PeriodList(period_id, e.target.value, isEvaluator, isSurvey, isNotComplete)}}>
                        <option value={""}>{""}</option>
                        {districtsResponse.map((row, rowIndex) => 
                            <option value={row["id"]}>{row["name"]}</option>
                        )}
                      </select>
                  </td>
              </tr>
              <tr><td><br/></td></tr>
              <tr>
                  <td><label>評価員</label></td>
                  <td>
                      <input type="checkbox" 
                        onChange={(e) => {get_request_PeriodList(period_id, m_districts_id, e.target.checked, isSurvey, isNotComplete)}} />
                  </td>
              </tr>
              <tr>
                  <td><label>調査員</label></td>
                  <td>
                      <input type="checkbox"
                        onChange={(e) => {get_request_PeriodList(period_id, m_districts_id, isEvaluator, e.target.checked, isNotComplete)}} />
                  </td>
              </tr>
              <tr>
                  <td><label>未完了のみ</label></td>
                  <td>
                    <input type="checkbox"
                        onChange={(e) => {get_request_PeriodList(period_id, m_districts_id, isEvaluator, isSurvey, e.target.checked)}} />
                  </td>
              </tr>
              <tr><td><br/></td></tr>
          </table>
      </div>
      <div className="table-container-for-judge-schedule-inquiry">
        <table className="table is-bordered has-text-left">
          <thead>
            <tr>
              <th>地区名</th>
              <th>氏名</th>
              <th>区分</th>
              <th>最終保存日</th>
              <th>完了日時</th>
              <th></th>
              <th></th>
              <th>備考1</th>
              <th>備考2</th>
            </tr>
          </thead>
          <tbody>
              {inquiryResponse.map((row, rowIndex) => 
              <tr>
                  <td width={80}>{row["district_name"]}</td>
                  <td width={160}>{row["name"]}</td>
                  <td width={80}>{row["evaluator_flag"] == true ? "評価員" : "調査員"}</td>
                  <td width={115}>{row["update_time"]}</td>
                  <td width={115}>{row["complete_time"]}</td>
                  <td width={100}>
                    <Link className="button is-info"
                      to={"/judge-schedule?judge_schedules_id=" + row["judge_schedules_id"] + "&isInquiry=true"}>入力内容照会</Link>
                  </td>
                  <td width={100}>
                    <button disabled={row["complete_time"] != "" ? false : true} className="button is-danger" onClick={() => confirmShow(row["judge_schedules_id"], row["name"])}>完了取り消し</button>
                  </td>
                  <td width={360}>{row["remarks1"]}</td>
                  <td width={360}>{row["remarks2"]}</td>
              </tr>
              )}
          </tbody>
        </table>
        <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
      </div>
      {/* 更新ダイアログ */}
      <div className={isShowModal ? 'modal is-active' : 'modal'}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="has-text-left">（{judge_name} 様）評価員・調査員スケジュール 完了取り消し</p>
            <div style={{ flexGrow: 1 }}></div>
            <button className="delete" aria-label="close" onClick={() => confirmClose()}/>
          </header>
          <section className="modal-card-body">
            <p className="has-text-left">【注意】<br/>
            {judge_name}様の評価員・調査員スケジュール入力について、完了取り消しを行います。</p>
            <p>取り消し後は、評価員・調査員各位で再度スケジュールを入力の上、改めて完了処理を行う必要があります。</p>
          </section>
          <footer className="modal-card-foot">
            <button className="button is-danger" onClick={() => confirmExecute()}>
              <span className="icon">
                <i className="fas fa-exclamation-triangle" />
              </span>
              <p>完了取り消し</p>
            </button>
            <button className="button" onClick={() => confirmClose()}>
              <span className="icon">
                <i className="fas fa-undo" />
              </span>
              <p>キャンセル</p>
            </button>
          </footer>
        </div>
      </div>
    </article>
  )
}

export default JudgeSchedulesInquiry