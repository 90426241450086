import React from 'react'

const AlertDialog = (isShow, isError, title, showMsg, clearError) => {
    var dspTitle=title
    var dspMsg=showMsg
    if(dspMsg==""){
        dspTitle=''
        dspMsg=title
    }
    return (
        <div className={isShow ? 'modal is-active' : 'modal'}>
            <div className="modal-background" />
            <article className={isError ? "message is-danger" : "message is-info"}>
                <div className="message-header">
                    <p>{dspTitle}</p>
                    <div style={{ flexGrow: 1 }}></div>
                    <button className="delete" aria-label="close" onClick={() => clearError()}/>
                </div>
                <div className="message-body notification">
                    {dspMsg.split('\n').map(m => <p>{m}</p>)}
                    <br />
                    <button className="button" aria-label="close" onClick={() => clearError()}>
                        <p>OK</p>
                    </button>
                </div>
            </article>
        </div>
    )
}
export default AlertDialog
