import React, {useState, useEffect} from 'react'
import apicall from '../utils/apicall'
import { Link } from 'react-router-dom'
import DownLoad from './DownLoad'
import AlertDialog from './AlertDialog'
import useCookie from "../utils/useCookie"
import ModalDialog from './ModalDialog'

const ExaminationSchedules = () => {
    const url = "examination-schedules"
    const url_csv = url + "/output"
    const url_report = url + "/transmittal-letter"
    const url_m = "m-districts-list/except-head"
    const url_j = "judge-examiantion-schedules/output"
    const url_j4a = "judge-examiantion-schedules/output-for-adjust"
    const[cookiePeriods_id, setCookiePeriods_id] = useCookie("periods_id", "")
    const[factoryExaminations, setFactoryExaminations] = useState([])
    const[districtListResponse, setDistrictListResponse] = useState([])
    const[period_id, setPeriod_id] = useState("3")
    const[districts_id, setDistrict_id] = useState("")
    const[headers_id, setHeaders_id] = useState("")
    const[isDistrictAll, setIsDistrictAll] = useState(true)
    const[isSelectAll, setIsSelectAll] = useState(false)

    const[isShowWarning, setIsShowWarning] = useState(false)
    const[warningMessage, setWarningMessage] = useState("")
    const[isShowModal, setIsShowModal] = useState(false)
    const[modifyRow, setModifyRow] = useState([])
    const[requestDateRows, setRequestDateRows] = useState([])

    const[isEvaluator, setIsEvaluator] = useState(false)
    const[isSurvey, setIsSurvey] = useState(false)
    const[factorySchedulesId, setFactorySchedulesId] = useState("")
    const[judgesSchedules, setJudgesSchedules] = useState([])
    const[requestDateNo, setRequestDateNo] = useState(-1)
    const[isSelectError, setIsSelectError] = useState(false)
    const[isError, setIsError] = useState(false)
    const[errorMsg, setErrorMsg] = useState("")
    const[documentNo, setDocumentNo] = useState("")
    const[isShowReport, setIsShowReport] = useState(false)
    const[isComplete, setIsComplete] = useState(false)
    const[completeMsg, setCompleteMsg] = useState("")
  
    const get_request_examination = async(p_id, m_districts_id) => {
        const response_content = await apicall(
            url + "?periods_id=" + p_id + "&districts_id=" + m_districts_id, "get")
        if (response_content) {
            setFactoryExaminations(response_content)
        }
    }
    const setDistrictChange = async(m_districts_id) => {
        // 地区変更
        setDistrict_id(m_districts_id)
        get_request_examination(period_id, m_districts_id)
        getAndSetFactoryScheduleHeaderId(m_districts_id)
        var all = m_districts_id=="" ? true : false
        setIsDistrictAll(all)
        setIsSelectAll(false)
    }

    const getAndSetFactoryScheduleHeaderId = async(d_id) => {
        if (d_id == "") setHeaders_id("");

        const data = await apicall(url + "/factory-schedule-headers?district_id=" + d_id, "get")
        if (data) {
            setHeaders_id(data)
        } else {
            setHeaders_id("")
        }
    }

    const setSelectAll = () => {
        factoryExaminations.map((row, rowIndex) => {
            setSelect(rowIndex, !isSelectAll)
        })

        setIsSelectAll(!isSelectAll)
    }

    const setSelect = (rowIndex, check) => {
        let r = factoryExaminations.slice(0, factoryExaminations.length)
        r[rowIndex]["isSelect"] = check
        setFactoryExaminations(r)
    }

    const outputSchedules = async() => {
        // 工場審査実施スケジュール一覧出力
        var csv_url = url_csv + "?periods_id=" + period_id + "&m_districts_id=" + districts_id
        const response = await apicall(csv_url, "get", {}, false)
        if (response) {
            let disposition = response.headers.get("content-disposition")
            DownLoad(disposition, await response.blob())

            if(districts_id==''){
                setCompleteMsg("デスクトップの帳票出力フォルダの中の「工場審査実施スケジュール一覧_全国」を実行してください")
            }else{
                setCompleteMsg("デスクトップの帳票出力フォルダの中の「工場審査実施スケジュール一覧_地区別」を実行してください")
            }
            setIsComplete(true)
        }
    }
    const reportOutput = async() => {
        // 送付状・依頼状出力
        let ids = []
        factoryExaminations.map((row, rowIndex) => {
            if (row["isSelect"]) {
                ids.push(row["factory_schedules_id"])
            }
        })
        if (ids.length == 0) {
            setIsSelectError(true)
            setErrorMsg("")
            return
        }
        if (documentNo === undefined || documentNo == "") {
            setIsError(true)
            setErrorMsg("")
            return
        }
        setIsShowReport(false)

        const response = await apicall(url_report, "post", {
            periods_id: period_id,
            districts_id: districts_id,
            document_no: documentNo,
            factory_schedules_id: ids
            }, false)
        if (response) {
          let disposition = response.headers.get("content-disposition")
          DownLoad(disposition, await response.blob())

          setCompleteMsg("デスクトップの帳票出力フォルダの中の「送付状・依頼状」を実行してください")
          setIsComplete(true)
          setIsSelectAll(false)
        }
    
        get_request_examination(period_id, districts_id)

    }

    const schedulesModify = async(rowIndex) => {
        var row = factoryExaminations[rowIndex]
        // 編集
        const data = await apicall(
            url + "/examination?factory_schedules_id=" + row["factory_schedules_id"], "get")
        if (data) {
            setIsEvaluator(false)
            setIsSurvey(false)
            setFactorySchedulesId(row["factory_schedules_id"])
            setRequestDateRows(data.requestDates)
            setModifyRow(data)
            setIsShowModal(true)
            setRequestDateNo(data.select_no)
            setJudgesSchedules(data.judgesScheduleRows)
            setIsShowModal(true)
        }
    }

    const setSelectNo = (value) => {
        setRequestDateNo(value)
        get_judgesSchedule(value, false, false)
        showJudgeLine(isEvaluator, isSurvey)
    }
    const setSame_day_flag = (checked) => {
        let r = Object.assign({}, modifyRow)
        r.same_day_flag = checked
        setModifyRow(r)
    }

    const getSelectJudgeId = (colid) => {
        let id = null
        judgesSchedules.forEach((row) => {
            if (row[colid]) {
                id = row["m_judges_id"]
            }
        })
        return id
    }
    const setSelectJudge = (index, type, checked) => {
        let rows = judgesSchedules.slice(0, judgesSchedules.length)
        let r = rows[index]
        if (type == 1) {
            if (checked == true) {
                r["isSubSelect"] = false
                r["isTempSelect"] = false
                rows.forEach((row) => 
                    row["isMainSelect"] = false
                )
            }
            r["isMainSelect"] = checked
        } else if (type == 2) {
            if (checked == true) {
                r["isMainSelect"] = false
                r["isTempSelect"] = false
                rows.forEach((row) => 
                    row["isSubSelect"] = false
                )
            }
            r["isSubSelect"] = checked
        } else if (type == 3) {
            if (checked == true) {
                r["isMainSelect"] = false
                r["isSubSelect"] = false
                rows.forEach((row) => 
                    row["isTempSelect"] = false
                )
            }
            r["isTempSelect"] = checked
        }
        setJudgesSchedules(rows)
    }

    const isSaveError = () => {
        let g = modifyRow.target.request_grade
        let rows = judgesSchedules
        let m_cnt = 0
        let s_cnt = 0
        let t_cnt = 0
        rows.map((row) => {
            if (row["isMainSelect"] == true) {
                m_cnt++
            }
            if (row["isSubSelect"] == true) {
                s_cnt++
            }
            if (row["isTempSelect"] == true) {
                t_cnt++
            }
        })
        let msg = ""
        if (requestDateNo == -1) {
            msg = msg + "審査日を選択して下さい。\n"
        }
        if (m_cnt == 0) {
            msg = msg + "メイン担当を選択して下さい。\n"
        } else if (m_cnt > 1) {
            msg = msg + "メイン担当が複数選択されています。\n"
        }
        if (s_cnt == 0) {
            msg = msg + "サブ担当を選択して下さい。\n"
        } else if (s_cnt > 1) {
            msg = msg + "サブ担当が複数選択されています。\n"
        }
        if (g != "S" && t_cnt > 0) {
            msg = msg + "申請グレードがS以外の場合、臨時担当を選択することはできません。\n"
        } else if (g == "S") {
            if (t_cnt == 0) {
                msg = msg + "臨時担当を選択して下さい。\n"
            } else if (t_cnt > 1) {
                msg = msg + "臨時担当が複数選択されています。\n"
            }
        }
        if (msg != "") {
            setIsError(true)
            setErrorMsg(msg)
            return true
        }
        setIsError(false)
        return false
    }

    const isWarning = (isThroughWarning) => {
        if (isThroughWarning) {
            clearWarning()
            return false
        }

        let m_disp_no = ""
        let s_disp_no = ""
        let t_disp_no = ""

        let rows = JSON.parse(JSON.stringify(judgesSchedules))

        rows.map((row) => {
            if (row["isMainSelect"] == true) {
                m_disp_no = row["rank"]
            }
            if (row["isSubSelect"] == true) {
                s_disp_no = row["rank"]
            }
            if (row["isTempSelect"] == true) {
                t_disp_no = row["rank"]
            }
        })

        if (m_disp_no == "" && s_disp_no == "" && t_disp_no == "") return false

        if (t_disp_no == "" && parseInt(m_disp_no) > parseInt(s_disp_no)) return true

        if (t_disp_no != ""
            && (parseInt(m_disp_no) > parseInt(s_disp_no) 
                || parseInt(m_disp_no) > parseInt(t_disp_no) 
                || parseInt(s_disp_no) > parseInt(t_disp_no))) return true

        return false
    }

    const clearError = () => {
        setIsSelectError(false)
        setIsError(false)
        setErrorMsg("")
    }
    const examinationSave = async(isThroughWarning) => {
        if (isSaveError()) {
            return
        }

        if (isWarning(isThroughWarning)) {
            setWarningMessage(
                "メイン・サブ・臨時担当者が評価員のランク順に設定されていません\n"
                + "※ OKをクリックするとこのまま保存します"
            )
            setIsShowWarning(true)
            return
        }

        let r = get_requestDateRow(requestDateNo)
        let mId = getSelectJudgeId("isMainSelect")
        let sId = getSelectJudgeId("isSubSelect")
        let tId = getSelectJudgeId("isTempSelect")

        const data = await apicall(url + "/save", "post", {
                periods_id: period_id,
                factory_schedules_id: factorySchedulesId,
                same_factory_schedules_id: modifyRow.target.same_factory_schedules_id,
                examinations_id: modifyRow.target.examinations_id,
                main_judges_id: mId,
                sub_judges_id:  sId,
                temp_judges_id: tId,
                examinations_id_same_day: modifyRow.target.examinations_id_same_day,
                same_day_flag: modifyRow.same_day_flag,
                examination_date: r["request_date"],
                ampm: r["ampm"]
            })
        if (data) {
            if (data != "NG") {
                updateaFactoryExaminations(data.target, data.same)
                modalHide()
                
                setCompleteMsg("保存しました")
                setIsComplete(true)
            }
        }
    }

    const updateaFactoryExaminations = (new_row, new_same_row) => {
        if (new_row === undefined) {
            return
        }
        let r = factoryExaminations.slice(0, factoryExaminations.length)
        for (var i = 0; i < r.length; i++) {
            if (r[i]["factory_schedules_id"] == new_row["factory_schedules_id"]) {
                r[i] = new_row
            }
            if (new_same_row !== undefined &&
                r[i]["factory_schedules_id"] == new_same_row["factory_schedules_id"]) {
                r[i] = new_same_row
            }
        }
        setFactoryExaminations(r)
    }

    const get_requestDateRow = (select_no) => {
        let r
        for (var i = 0; i < requestDateRows.length; i++) {
            if (requestDateRows[i]["no"] == select_no) {
                r = requestDateRows[i]
                break
            }
        }
        return r
    }

    const get_judgesSchedule = async(select_no, is_Evaluator, is_Survey) => {
        setIsEvaluator(is_Evaluator)
        setIsSurvey(is_Survey)
        let r = get_requestDateRow(select_no)
        if (r === undefined) {
            return
        }
        const data = await apicall(url + "/judge-schedules?factory_schedules_id=" + factorySchedulesId +
            "&isEvaluator=" + is_Evaluator + "&isSurvey=" + is_Survey +
            "&schedule_date=" + r["request_date"] + "&ampm=" + r["ampm"], "get")
        if (data) {
            setJudgesSchedules(data)
        }
    }
    const modalHide = () => {
        setModifyRow([])
        setFactorySchedulesId("")
        setIsEvaluator(false)
        setIsSurvey(false)
        setJudgesSchedules([])
        setRequestDateRows([])
        setIsShowModal(false)
    }

    const outputJudgeSchedule = async(evaluator) => {
        var endpoint = url_j + "?periods_id=" + period_id + "&m_districts_id=" + districts_id + "&evaluator_flag=" + evaluator
        const response = await apicall(endpoint, "get", {}, false)
        if (response) {
            let disposition = response.headers.get("content-disposition")
            DownLoad(disposition, await response.blob())
        }
    }

    const outputJudgeScheduleForAdjust = async() => {
        var endpoint = url_j4a + "?periods_id=" + period_id + "&m_districts_id=" + districts_id
        const response = await apicall(endpoint, "get", {}, false)
        if (response) {
            let disposition = response.headers.get("content-disposition")
            DownLoad(disposition, await response.blob())
        }
    }

    useEffect(async() => {
        var p_id = period_id
        if (cookiePeriods_id) {
            setPeriod_id(cookiePeriods_id)
            p_id = cookiePeriods_id
        }
        const data = await apicall(url_m, "get")
        if (data) {
            setDistrictListResponse(data)
            get_request_examination(p_id, districts_id)
        }
    }, [])


    const showReportDialog = async() => {
        let ids = []
        factoryExaminations.map((row, rowIndex) => {
            if (row["isSelect"]) {
                ids.push(row["factory_schedules_id"])
            }
        })
        if (ids.length == 0) {
            setIsSelectError(true)
            setErrorMsg("")
            return
        }

        const data = await apicall(url_report + "/document-no?periods_id=" + period_id, "get")
        if (data) {
            setDocumentNo(data.document_no)
            setIsShowReport(true)
        }
    }

    const showJudgeLine = (is_Evaluator, is_Survey) => {
        setIsEvaluator(is_Evaluator)
        setIsSurvey(is_Survey)
        var dspEval = ''
        var dspSur = ''
        if(is_Evaluator&&!is_Survey){
            dspSur = 'none'
        }
        if(!is_Evaluator&&is_Survey){
            dspEval = 'none'
        }
        var i
        var clsJudge0 = document.getElementsByClassName('judge0')
        for(i=0;i<clsJudge0.length;i++){
            clsJudge0[i].style.display = dspEval
        }
        var clsJudge1 = document.getElementsByClassName('judge1')
        for(i=0;i<clsJudge1.length;i++){
            clsJudge1[i].style.display = dspSur
        }
    }

    const clearWarning = () => {
        setWarningMessage("")
        setIsShowWarning(false)
    }

    const clearMsg = () => {
        setCompleteMsg("")
        setIsComplete(false)
    }

    return(
        <article>

        <ModalDialog
            isShow={isComplete}
            messageAttribute={"is-info"}
            title={""}
            message={completeMsg}
            ok={clearMsg}
            close={clearMsg}></ModalDialog>
        
        <ModalDialog
            isShow={isSelectError}
            messageAttribute={"is-danger"}
            title={""}
            message={"審査が選択されていません"}
            ok={clearError}
            close={clearError}></ModalDialog>
        
        <div className="level">
        <label className="label">工場審査スケジュール入力</label>
        </div>
        <div className="has-text-left">
            <td width={150}>
                <label className="subtitle">地区</label>
            </td>
            <td>
                <select className="select" value={districts_id} style={{width: 100}}
                    onChange={(e) => {setDistrictChange(e.target.value)}}>
                    <option value={""}>{"全国"}</option>
                    {districtListResponse.map((row, rowIndex) => 
                        <option value={row["id"]}>{row["name"]}</option>
                    )}
                </select>
            </td>
        </div>
        <div><br/></div>
        <div className="has-text-left">
            <td width={350}>
                <button className="button is-info" onClick={() => outputSchedules()}>工場審査実施スケジュール一覧出力</button>
            </td>
            <td width={260}>
                <Link className="button is-info"
                    to={"/judge-schedules-inquiry?district_id=" + districts_id}>
                        評価員スケジュール管理
                </Link>
            </td>
            <td width={250}>
                {isDistrictAll ? <button className="button is-info" disabled={isDistrictAll}>工場スケジュール管理</button> : 
                <Link className="button is-info" to={"/factory-schedules?headers_id=" + headers_id} disabled={isDistrictAll}>
                    工場スケジュール管理
                </Link>}
                
            </td>
            <td width={250}>
                <button className="button is-info" onClick={() => showReportDialog()}>送付状・依頼状出力</button>
            </td>
            <td width={350}>
                <button className="button is-info" disabled={isDistrictAll} onClick={() => outputJudgeSchedule(true)}>評価員スケジュール表出力</button>
            </td>
            <td width={350}>
                <button className="button is-info" disabled={isDistrictAll} onClick={() => outputJudgeSchedule(false)}>調査員スケジュール表出力</button>
            </td>
            <td width={350}>
                <button className="button is-info" disabled={isDistrictAll} onClick={() => outputJudgeScheduleForAdjust()}>審査担当調整表出力</button>
            </td>
        </div>
        <div><br/></div>
        <div className="table-container-for-examination-schedule">
            <table className="table is-fullwidth is-bordered has-text-left">
                <thead>
                    <tr>
                        <th width={150}>
                            <input type="checkbox" checked={isSelectAll} onChange={() => {setSelectAll()}} />
                        </th>
                        <th>地区</th>
                        <th>県</th>
                        <th>現G</th>
                        <th>申請G</th>
                        <th>工場名</th>
                        <th>市町村</th>
                        <th>同日No.</th>
                        <th>審査日</th>
                        <th>送付状出力日</th>
                        <th>メイン担当者</th>
                        <th>サブ担当者</th>
                        <th>臨時担当者</th>
                    </tr>
                </thead>
                <tbody>
                    {factoryExaminations.map((row, rowIndex) => 
                        <tr>
                            <td>
                                <div className="level">
                                    {rowIndex + 1}
                                    <input type="checkbox" checked={row["isSelect"]} onChange={(e) => {setSelect(rowIndex, e.target.checked)}} />
                                    <button className="button is-info" onClick={() => schedulesModify(rowIndex)}>編集</button>
                                </div>
                            </td>
                            <td>{row["district_name"]}</td>
                            <td>{row["prefecture_name"]}</td>
                            <td>{row["current_grade"]}</td>
                            <td>{row["request_grade"]}</td>
                            <td>{row["name"]}</td>
                            <td>{row["address"]}</td>
                            <td>{row["same_day_no"]}</td>
                            <td>{row["examination_date"] + ' ' + row["ampm"]}</td>
                            <td>{row["report_output_date"]}</td>
                            <td>{row["main_judge_name"]}</td>
                            <td>{row["sub_judge_name"]}</td>
                            <td>{row["temp_judge_name"]}</td>
                        </tr>
                    )}
                </tbody>
            </table>
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
        </div>

        {/* 編集ダイアログ */}
        <div className={isShowModal ? 'modal is-active' : 'modal'}>

            <ModalDialog
                isShow={isShowWarning}
                messageAttribute={"is-warning"}
                title={"注意"}
                message={warningMessage}
                ok={examinationSave}
                close={clearWarning}></ModalDialog>

            <ModalDialog
                isShow={isError}
                messageAttribute={"is-danger"}
                title={"入力内容に誤りがあります"}
                message={errorMsg}
                ok={clearError}
                close={clearError}></ModalDialog>

            <div className="modal-background" />
            <div className="modal-card">
            <header className="modal-card-head">
                <p className="has-text-left">審査スケジュール編集</p>
                <div style={{ flexGrow: 1 }}></div>
                <button className="delete" aria-label="close" onClick={() => modalHide()} />
            </header>
            <section className="modal-card-body-has-sticky">

                <table className="has-text-left sticky full-width">
                    <tr>
                        <td className="top"><label>県名</label></td>
                        {/* <td></td> */}
                        <td className="top">
                            {(modifyRow.target === undefined) ? '' : modifyRow.target["prefecture_name"]}
                        </td>
                    </tr>
                    <tr>
                        <td><label>工場名</label></td>
                        {/* <td></td> */}
                        <td>
                            {(modifyRow.target === undefined) ? '' : modifyRow.target["name"]}
                        </td>
                    </tr>
                    <tr>
                        <td><label>申請グレード</label></td>
                        {/* <td></td> */}
                        <td>
                            {(modifyRow.target === undefined) ? '' : modifyRow.target["request_grade"]}
                        </td>
                    </tr>
                    <tr>
                        <td><label>同日実施希望工場</label></td>
                        {/* <td></td> */}
                        <td>
                            {(modifyRow.same === undefined) ? '' : modifyRow.same["name"]}
                        </td>
                    </tr>
                    <tr>
                        <td><label>申請グレード</label></td>
                        {/* <td></td> */}
                        <td>
                            {(modifyRow.same === undefined) ? '' : modifyRow.same["request_grade"]}
                        </td>
                    </tr>
                    <tr><td><br/></td></tr>
                    <tr>
                        <td><label>審査日</label></td>
                        {/* <td></td> */}
                        <td>
                            <select className="select" value={requestDateNo} 
                                onChange={(e) => {setSelectNo(e.target.value)}}>
                                <option value={-1}>{""}</option>
                                {requestDateRows.map((row, rowIndex) => 
                                    <option value={row["no"]}>{row["request"]}</option>
                                )}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td><label>同日実施</label></td>
                        {/* <td></td> */}
                        <td style={{ display: modifyRow.isShowSame_day_flag ? '' : 'none' }}>
                            <input type="checkbox" checked={modifyRow.same_day_flag} onChange={(e) => {setSame_day_flag(e.target.checked)}} />
                        </td>
                    </tr>
                    <tr><td><br/></td></tr>
                    <tr style={{display:
                            modifyRow.target !== undefined
                            && (modifyRow.target["request_grade"] == 'R' 
                            || modifyRow.target["request_grade"] == 'J') ? '' : 'none'}}>
                        <td><label>評価員のみ　</label></td>
                        <td>
                            <input type="checkbox" id="chkEvaluator" checked={isEvaluator}
                                onChange={(e) => {showJudgeLine(e.target.checked, isSurvey)}} />
                        </td>
                    </tr>
                    <tr style={{display:
                            modifyRow.target !== undefined
                            && (modifyRow.target["request_grade"] == 'R' 
                            || modifyRow.target["request_grade"] == 'J') ? '' : 'none'}}>
                        <td><label>調査員のみ </label></td>
                        <td>
                            <input type="checkbox" id="chkSurvey" checked={isSurvey}
                                onChange={(e) => {showJudgeLine(isEvaluator, e.target.checked)}} />
                        </td>
                    </tr>
                    <tr><td><br/></td></tr>
                </table>
                <table className="table is-fullwidth is-bordered has-text-left sticky">
                    <thead>
                    <tr>
                        <th>メイン<br/>担当</th>
                        <th>サブ<br/>担当</th>
                        <th
                            style={{display:
                                modifyRow.target !== undefined 
                                && modifyRow.target["request_grade"] != 'S' ? 'none' : ''}}>
                            臨時<br/>担当
                        </th>
                        <th>スケジュール</th>
                        <th>地区</th>
                        <th>県</th>
                        <th>分類</th>
                        <th>ランク</th>
                        <th>氏名</th>
                    </tr>
                    </thead>
                    <tbody>
                        {judgesSchedules.map((row, rowIndex) => 
                        <tr className={row["evaluator_flag"] == true ? "judge0" : "judge1"}>
                            <td>
                                <input
                                    type="checkbox"
                                    style={{display: !row["evaluator_flag"] ? 'none' : ''}}
                                    checked={row["isMainSelect"]}
                                    onChange={(e) => {setSelectJudge(rowIndex, 1, e.target.checked)}} />
                            </td>
                            <td>
                                <input type="checkbox" checked={row["isSubSelect"]} onChange={(e) => {setSelectJudge(rowIndex, 2, e.target.checked)}} />
                            </td>
                            <td
                                style={{display:
                                    modifyRow.target !== undefined 
                                    && modifyRow.target["request_grade"] != 'S' ? 'none' : ''}}>
                                <input type="checkbox" checked={row["isTempSelect"]} onChange={(e) => {setSelectJudge(rowIndex, 3, e.target.checked)}} />
                            </td>
                            <td>{row["schedule_value"]}</td>
                            <td>{row["district_name"]}</td>
                            <td>{row["prefecture_name"]}</td>
                            <td>{row["evaluator_flag"] == true ? "評価員" : "調査員"}</td>
                            <td>{row["rank"]}</td>
                            <td>{row["name"]}</td>
                        </tr>
                        )}
                    </tbody>
                </table>
            </section>
            <footer className="modal-card-foot">
                <button className="button" onClick={() => examinationSave()} >
                    <span className="icon">
                        <i className="fas fa-info-circle" />
                    </span>
                    <p>保存</p>
                </button>
                <button className="button" onClick={() => modalHide()} >
                    <span className="icon">
                        <i className="fas fa-ban" />
                    </span>
                    <p>キャンセル</p>
                </button>
            </footer>
            </div>
        </div>
        <div className={isShowReport ? 'modal is-active' : 'modal'}>
            <ModalDialog
                isShow={isError}
                messageAttribute={"is-danger"}
                title={""}
                message={"文書番号を入力してください"}
                ok={clearError}
                close={clearError}
            />
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="has-text-left">工場審査資料送付状　出力指示</p>
                    <div style={{ flexGrow: 1 }}></div>
                    <button className="delete" aria-label="close" onClick={() => setIsShowReport(false)} />
                </header>
                <section className="modal-card-body">
                    <table className="has-text-left">
                        <tr>
                            <td width={100}><label>文書番号</label></td>
                            <td>
                                <input className="input" type="text" value={documentNo} style={{width: 300}} maxLength={15} onChange={(e) => {setDocumentNo(e.target.value);}}/>
                            </td>
                            <td width={50}></td>
                            <td>
                                <button className="button" onClick={() => reportOutput()} >
                                    <p>　出力　</p>
                                </button>
                            </td>
                        </tr>
                    </table>
                </section>
                <footer className="modal-card-foot">
                </footer>
            </div>
        </div>
        </article>
    )
}

export default ExaminationSchedules

