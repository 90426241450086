import React, {useState, useEffect} from 'react'
import apicall from '../utils/apicall'
import AlertDialog from './AlertDialog'
import useCookie from "../utils/useCookie"

const Login = () => {
    const[yaersResponse, setYaersResponse] = useState([])
    const[periodsResponse, setperiodsResponse] = useState([])
    const[year, setYear] = useState("")
    const[period, setPeriod] = useState(-1)
    const[isError, setIsError] = useState(false)
    const[isLoading, setIsLoading] = useState(false)
    const[errorMsg, setErrorMsg] = useState("")

    const[cookiePeriods_id, setCookiePeriods_id] = useCookie("periods_id", "")
    const[cookieRole, setCookieRole] = useCookie("role", "")
    const[cookieId, setCookieId] = useCookie("id", "")

    const url = "login"
    useEffect(async() => {
        setCookiePeriods_id("", -1)
        setCookieRole("", -1)
        setCookieId("", -1)
        const response_content = await apicall(url + "/year", "get")
        if (response_content) {
          setYaersResponse(response_content)
        }
    }, [])

    const change_year = async(p_year) => {
        setYear(p_year)
        const response_content = await apicall(url + "/period?year=" + p_year, "get")
        if (response_content) {
            setperiodsResponse(response_content)
        }
        setPeriod(-1)
    }

    const login = async() => {
        // ログイン
        setIsLoading(true)
        const response_content = await apicall(url, "post", { year: year, period_id: period })
        if (response_content) {
            if (response_content == "OK") {
                setCookiePeriods_id(period, 1)
  //              window.location.href = "/one-time-password?period_id=" + period
                window.location.href = "/one-time-password"
            } else {
                setIsError(true)
                setErrorMsg(response_content)
            }
        }
        setIsLoading(false)
    }
    const clearError = () => {
      setIsError(false)
      setErrorMsg("")
    }

  return(
    <article>
      <div className="has-text-centered">
        <label className="subtitle" >年度　</label>
        <select className="select"
            style={{width: 100}}
            value={year} 
            onChange={(e) => {change_year(e.target.value)}}>
              <option value={""}>{""}</option>
              {yaersResponse.map((row, rowIndex) => 
                  <option value={row["code"]}>{row["name"]}</option>
              )}
        </select>
      </div>
      <div><br/></div>
      <div className="has-text-centered">
        <label className="subtitle">期　　</label>
        <select className="select"
            style={{width: 100}}
            value={period}
            onChange={(e) => {setPeriod(e.target.value)}}>
              <option value={-1}>{""}</option>
              {periodsResponse.map((row, rowIndex) => 
                  <option value={row["id"]}>{row["name"]}</option>
              )}
        </select>
      </div>
      <div><br/></div>
      <div className="has-text-centered">
      <button className={isLoading ? "button is-loading" : "button is-info"} onClick={() => login()}>ログイン</button>
      </div>
      {AlertDialog(isError, true, "未入力箇所があります", errorMsg, clearError)}
    </article>
  )
}

export default Login