import React, {useState, useEffect} from 'react'
import apicall from '../utils/apicall'
import { useLocation } from 'react-router-dom'
import AlertDialog from './AlertDialog'
import useCookie from "../utils/useCookie"

const OneTimePassword = () => {
  const location = useLocation()
  const[mailsId, setMailsId] = useState("")
  const[periods_id, setPeriods_id] = useState("")
  const[prefectures_id, setPrefectures_id] = useState("")
  const[judges_id, setJudges_id] = useState("")
  const[m_districts_id, setM_districts_id] = useState("")
  const[judge_schedules_id, setJudge_schedules_id] = useState("")
  const[headers_id, setHeaders_id] = useState("")
  const[password, setPassword] = useState("")
  const[isError, setIsError] = useState(false)
  const[errorTitle, setErrorTitle] = useState("")
  const[errorMsg, setErrorMsg] = useState("")
  const url = "one-time-password"

  const[cookiePeriods_id, setCookiePeriods_id] = useCookie("periods_id", "")
  const[cookieRole, setCookieRole] = useCookie("role", "")
  const[cookieId, setCookieId] = useCookie("id", "")
  const [cookiePeriod, setCookiePeriod] = useCookie("period", "")

  const passwordAuthentication = async() => {
    const data = await apicall(url, "post",
        {
          mails_id: mailsId,
          password: password,
          periods_id: periods_id,
          prefectures_id: prefectures_id,
          judges_id: judges_id
        })

    if (data.status == "OK") {
      setCookiePeriods_id(data.periods_id, 1)
      if (data.role) {
        setCookieRole(data.role, 1)
      }
      setCookieId(data.id, 1)
      setCookiePeriod(data.period)

      if (mailsId !== undefined && mailsId != "") {
        if (judges_id !== undefined && judges_id != "") {
          window.location.href = "/judge-schedule?judge_schedules_id=" + judge_schedules_id + "&isInquiry=false"
        } else if (prefectures_id !== undefined && prefectures_id != "") {
          window.location.href = "/factory-schedules?headers_id=" + headers_id + "&prefectures_id=" + prefectures_id
        }
        return
      } else {
        // Home
        window.location.href = "/"
        return
      }
    } else {
      setIsError(true)
      setErrorTitle("ログイン番号が間違っています。")
      setErrorMsg(data.message)
    }
  }
  const clearError = () => {
    setIsError(false)
    setErrorTitle("")
    setErrorMsg("")
  }

  useEffect(async() => {
    setCookieRole("", -1)
    setCookieId("", -1)

    var s = window.location.href

    // メールからかチェック
    const data = await apicall(url + "/request-password", "post", { url: s })
    if (data) {
      if (data.status == "OK") {
        setMailsId(data.mail_id)
        setPeriods_id(data.periods_id)
        setPrefectures_id(data.prefectures_id)
        setJudges_id(data.judges_id)
        setM_districts_id(data.m_districts_id)
        setJudge_schedules_id(data.judge_schedules_id)
        setHeaders_id(data.headers_id)
        return
      } else if (data.message != "") {
        setIsError(true)
        setErrorTitle("ログイン番号を発行出来ませんでした。")
        setErrorMsg(data.message)
        return
      }
    }
    // ログインから
    setPeriods_id(cookiePeriods_id)

  }, [])

  return(
    <article>
      <label className="label">ログイン画面</label>
      <div><br/><br/></div>
      <div className="has-text-centered">
        <label>ログイン番号入力（ログイン番号はご指定のメールアドレスへ通知いたしました）</label>
      </div>
      <div><br/></div>
      <div className="has-text-centered">
        <input className="input" type="text" style={{width: 400}}　onChange={(e) => setPassword(e.target.value)} />
      </div>
      <div><br/></div>
      <div className="has-text-centered">
        <label>※ログイン番号を入力してログインボタンをクリックして下さい</label>
      </div>
      <div><br/><br/></div>
      <div className="has-text-centered">
      <button className="button is-info" onClick={() => passwordAuthentication()}>ログイン</button>
      </div>
      {AlertDialog(isError, true, errorTitle, errorMsg, clearError)}
    </article>
  )
}

export default OneTimePassword