import React from 'react'

const MailSendPreview = (isShowModal, setIsShowModal, send, mail_preview, attach_filename1, attach_filename2, attach_filename3) => {
    return (
        <div className={isShowModal ? 'modal is-active' : 'modal'}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title has-text-left">送信メール プレビュー</p>
            <div style={{ flexGrow: 1 }}></div>
            <button className="delete" aria-label="close" onClick={() => setIsShowModal(false)}/>
          </header>
          <section className="modal-card-body">
            <div className="level has-text-left">
              <table>
                <tr><td>件名</td></tr>
                <tr>
                  <td><pre>{mail_preview.title}</pre></td>
                </tr>
                <tr><td>添付ファイル</td></tr>
                <tr>
                  <td>
                    <pre>
                    {attach_filename1}
                    {attach_filename1 != "" && attach_filename2 !="" ? <br/> : ""}
                    {attach_filename2}
                    {(attach_filename1 != "" || attach_filename2 !="") && attach_filename3 !="" ? <br/> : ""}
                    {attach_filename3}
                    </pre>
                  </td>
                </tr>
                <tr><td><br/></td></tr>
                <tr>
                  <td><pre>{mail_preview.body}</pre></td>
                </tr>
              </table>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button className="button is-success" onClick={() => send()}>
              <span className="icon">
                <i className="fas fa-envelope-open-text" />
              </span>
              <p>送信</p>
            </button>
          </footer>
        </div>
      </div>
      )
}
export default MailSendPreview
