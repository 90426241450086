import React, {useState} from 'react'

const FactoryScheduleHelp = (props) => {

  return (
    <article>
      <div className="modal-background"  onClick={() => props.activate(false)} />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="has-text-left">工場スケジュール入力 - ヘルプ</p>
          <div style={{ flexGrow: 1 }}></div>
          <button className="delete" aria-label="close" onClick={() => props.activate(false)} />
        </header>
        <section className="modal-card-body">
          <div className="help-header">
            <label className="subtitle">カレンダー入力部の入力内容に関する説明</label>
          </div>
          <div className="help-body">
          <p>工場の審査希望日をカレンダーに入力する際は、<br />
          以下の表を参考に、入力内容を選択してください。</p>
          
          <table className="table is-bordered">
            <thead>
              <tr><th>希望日程</th><th>選択値</th></tr>
            </thead>
            <tbody>
              <tr><td>第一希望 終日</td><td>1</td></tr>
              <tr><td>第二希望 終日</td><td>2</td></tr>
              <tr><td>第三希望 終日</td><td>3</td></tr>
              <tr><td>第一希望 午前</td><td>1A</td></tr>
              <tr><td>第二希望 午前</td><td>2A</td></tr>
              <tr><td>第三希望 午前</td><td>3A</td></tr>
              <tr><td>第一希望 午後</td><td>1P</td></tr>
              <tr><td>第二希望 午後</td><td>2P</td></tr>
              <tr><td>第三希望 午後</td><td>3P</td></tr>
            </tbody>
          </table>

          <p>(例) ある工場の審査希望日程に関して、1月1日の午前を第一希望としている場合は、<br />
          カレンダーの1月1日の欄に、"1A"と選択してください。
          </p>
          
          </div>
        </section>
        <footer className="modal-card-foot">
        </footer>
      </div>
    </article>
  )
}

export default FactoryScheduleHelp