

const DownLoad = (disposition, blob) => {
    var filename = ""
    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    var matches = filenameRegex.exec(disposition)
    if (matches != null && matches[1]) { 
        filename = matches[1].replace(/['"]/g, '')
    }
    const url = URL.createObjectURL(blob)
    const a = document.createElement("a")
    document.body.appendChild(a)
    a.download = decodeURI(filename)
    a.href = url
    
    a.click()
    a.remove()
    setTimeout(() => {
        URL.revokeObjectURL(url)
    }, 1E4)
  }
  export default DownLoad

