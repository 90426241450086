import React, {useState, useEffect, useRef} from 'react';
import Row from './Row';

const Body = (props) => {

  const [editable, _setEditable] = useState("")

  const editableRef = useRef(editable)

  const setEditable = (i) => {
    editableRef.current = i
    _setEditable(i)
  }

  const [isEditing, _setIsEditing] = useState(false)

  const isEditingRef = useRef(isEditing)

  const setIsEditing = (bool) => {
    isEditingRef.current = bool
    _setIsEditing(bool)
  }

  const [isSelecting, setIsSelecting] = useState(false)

  const [firstSelectXY, _setFirstSelectXY] = useState({})

  const firstSelectXYRef = useRef(firstSelectXY)

  const setFirstSelectXY = (xy) => {
    firstSelectXYRef.current = xy;
    _setFirstSelectXY(xy);
  }

  const [endSelectXY, _setEndSelectXY] = useState({})

  const endSelectXYRef = useRef(endSelectXY)

  const setEndSelectXY = (xy) => {
    endSelectXYRef.current = xy
    _setEndSelectXY(xy)
  }

  const [selectedCells, _setSelectedCells] = useState([])

  const selectedCellsRef = useRef(selectedCells)

  const setSelectedCells = (selectedCells) => {
    selectedCellsRef.current = selectedCells;
    _setSelectedCells(selectedCells);
  }

  const tbodyRef = useRef()

  const documentClickHandler = useRef()

  useEffect(() => {

    documentClickHandler.current = e => {
      if (tbodyRef.current === null) return
      if (tbodyRef.current.contains(e.target)) return
      
      setFirstSelectXY({})
      setEndSelectXY({})

      removeDocumentClickHandler()
    }

    document.addEventListener('copy', detectCopy)
    document.addEventListener('paste', detectPaste)
    document.addEventListener('keydown', detectKeyDown)
  }, [])
  


  const detectCopy = (e) => {
    if (props.spreadSheetRef.current == null) {
      document.removeEventListener('copy', detectCopy)
      return
    }

    if (isEditingRef.current
        || Object.keys(firstSelectXYRef.current).length === 0) {
      return
    }

    e.preventDefault()

    if (selectedCellsRef.current.length === 0) {
      return
    }

    var yStart = selectedCellsRef.current.map(key => key.split('-')[1]).reduce((a,b)=>Math.min(a,b))
    var yEnd = selectedCellsRef.current.map(key => key.split('-')[1]).reduce((a,b)=>Math.max(a,b))
    var xEnd = selectedCellsRef.current.map(key => key.split('-')[0]).reduce((a,b)=>Math.max(a,b))

    var d = 0
    var indentPoints = []
    for (let i = yStart; d < yEnd - yStart; i++) {
      indentPoints.push(xEnd + '-' + i)
      d++
    }

    var selectedValues = selectedCellsRef.current.map(key => {
      if (indentPoints.some(ip => ip == key)) {
        return props.datasheetRef.current[key] + '\r\n'
      } else if (key.split('-')[0] == xEnd) {
        return props.datasheetRef.current[key]
      }
      return props.datasheetRef.current[key] + '\t'
    })

    e.clipboardData.setData('text/plain', selectedValues.join(''))
  }

  const detectPaste = (e) => {
    if (props.spreadSheetRef.current == null) {
      document.removeEventListener('paste', detectPaste)
      return
    }

    if (isEditingRef.current
        || Object.keys(firstSelectXYRef.current).length === 0) {
      return
    }

    e.preventDefault()

    const pasteData = e.clipboardData.getData('text/plain')

    var ds = props.datasheetRef.current
    var nowX = firstSelectXYRef.current.x
    var lastX = endSelectXYRef.current.x

    // コピー要素の中に改行があっても無視して１行目しか取らない
    var pd1 = pasteData.split('\r\n')[0];

    // ペースト処理(単一セル選択)
    if (Object.keys(endSelectXYRef.current).length === 0) {
      pd1.split('\t').forEach((pd) => {
        if (props.rowsRef.current[firstSelectXYRef.current.y][nowX] === undefined) return

        if (props.rowsRef.current[firstSelectXYRef.current.y][nowX].options.cell_info != '3'
          && props.rowsRef.current[firstSelectXYRef.current.y][nowX].options.type !== "static") {

          ds[nowX + '-' + firstSelectXYRef.current.y] = pd.trim()
        }
        ++nowX
      })
    // ペースト処理(複数セル選択)
    } else {
      let pasteValue = pd1.split('\t')[0];
      selectedCellsRef.current.forEach(xy => {

        if (props.rowsRef.current[xy.split('-')[1]][xy.split('-')[0]].options.cell_info != '3'
        && props.rowsRef.current[xy.split('-')[1]][xy.split('-')[0]].options.type !== "static") {

          ds[xy] = pasteValue.trim()
        }
      })
    }

    props.setDatasheet(ds)
  }

  const detectKeyDown = (e) => {
    if (props.spreadSheetRef.current == null) {
      document.removeEventListener('keydown', detectKeyDown)
      return
    }

    if (isEditingRef.current) return

    console.log(e.shiftKey)

    if (Object.keys(firstSelectXYRef.current).length !== 0) {

      if (!e.shiftKey) {

        if (e.key === 'ArrowLeft') {
          if (props.rowsRef.current[firstSelectXYRef.current.y][firstSelectXYRef.current.x - 1] !== undefined) {
            e.preventDefault()

            firstSelectXYRef.current.x = firstSelectXYRef.current.x - 1
            let cellIndex = []
            cellIndex.push(firstSelectXYRef.current.x + '-' + firstSelectXYRef.current.y)
            setEndSelectXY({})
            setSelectedCells(cellIndex)
            setEditable("")
          }
        } else if (e.key === 'ArrowRight') {
          if (props.rowsRef.current[firstSelectXYRef.current.y][firstSelectXYRef.current.x + 1] !== undefined) {
            e.preventDefault()

            firstSelectXYRef.current.x = firstSelectXYRef.current.x + 1
            let cellIndex = []
            cellIndex.push(firstSelectXYRef.current.x + '-' + firstSelectXYRef.current.y)
            setEndSelectXY({})
            setSelectedCells(cellIndex)
            setEditable("")
          }
        } else if (e.key === 'ArrowDown') {
          if (props.rowsRef.current[firstSelectXYRef.current.y + 1] !== undefined) {
            e.preventDefault()

            firstSelectXYRef.current.y = firstSelectXYRef.current.y + 1
            let cellIndex = []
            cellIndex.push(firstSelectXYRef.current.x + '-' + firstSelectXYRef.current.y)
            setEndSelectXY({})
            setSelectedCells(cellIndex)
            setEditable("")
          }
        } else if (e.key === 'ArrowUp') {
          if (props.rowsRef.current[firstSelectXYRef.current.y - 1] !== undefined) {
            e.preventDefault()

            firstSelectXYRef.current.y = firstSelectXYRef.current.y - 1
            let cellIndex = []
            cellIndex.push(firstSelectXYRef.current.x + '-' + firstSelectXYRef.current.y)
            setEndSelectXY({})
            setSelectedCells(cellIndex)
            setEditable("")
          }
        }

      } else {
        if (e.key === 'ArrowLeft') {
          if (Object.keys(endSelectXYRef.current).length === 0)
            endSelectXYRef.current = JSON.parse(JSON.stringify(firstSelectXYRef.current))

          if (props.rowsRef.current[endSelectXYRef.current.y][endSelectXYRef.current.x - 1] !== undefined) {
            e.preventDefault()

            endSelectXYRef.current.x = endSelectXYRef.current.x - 1
            setEndSelectXY(endSelectXYRef.current)
            pushSelectedCells()
            setEditable("")
          }
        } else if (e.key === 'ArrowRight') {
          if (Object.keys(endSelectXYRef.current).length === 0)
            endSelectXYRef.current = JSON.parse(JSON.stringify(firstSelectXYRef.current))

          if (props.rowsRef.current[endSelectXYRef.current.y][endSelectXYRef.current.x + 1] !== undefined) {
            e.preventDefault()

            endSelectXYRef.current.x = endSelectXYRef.current.x + 1
            setEndSelectXY(endSelectXYRef.current)
            pushSelectedCells()
            setEditable("")
          }
        } else if (e.key === 'ArrowDown') {
          if (Object.keys(endSelectXYRef.current).length === 0)
            endSelectXYRef.current = JSON.parse(JSON.stringify(firstSelectXYRef.current))

          if (props.rowsRef.current[endSelectXYRef.current.y + 1] !== undefined) {
            e.preventDefault()

            endSelectXYRef.current.y = endSelectXYRef.current.y + 1
            setEndSelectXY(endSelectXYRef.current)
            pushSelectedCells()
            setEditable("")
          }
        } else if (e.key === 'ArrowUp') {
          if (Object.keys(endSelectXYRef.current).length === 0)
            endSelectXYRef.current = JSON.parse(JSON.stringify(firstSelectXYRef.current))

          if (props.rowsRef.current[endSelectXYRef.current.y - 1] !== undefined) {
            e.preventDefault()

            endSelectXYRef.current.y = endSelectXYRef.current.y - 1
            setEndSelectXY(endSelectXYRef.current)
            pushSelectedCells()
            setEditable("")
          }
        }
      }
    
    }
  }

  const removeDocumentClickHandler = () => {
    document.removeEventListener('click', documentClickHandler.current)
    setEditable("")
    setSelectedCells([])
  }

  const handleMoveEndSelect = (x, y) => {
    setEndSelectXY({x: x, y: y})
  }

  const pushSelectedCells = () => {
    var selecteds = []
    if(Object.keys(endSelectXYRef.current).length == 0) {
      selecteds.push(firstSelectXYRef.current.x + "-" + firstSelectXYRef.current.y)
    } else {
      var startx = firstSelectXYRef.current.x <= endSelectXYRef.current.x ? firstSelectXYRef.current.x : endSelectXYRef.current.x
      var endx = firstSelectXYRef.current.x >= endSelectXYRef.current.x ? firstSelectXYRef.current.x : endSelectXYRef.current.x
      var starty = firstSelectXYRef.current.y <= endSelectXYRef.current.y ? firstSelectXYRef.current.y : endSelectXYRef.current.y
      var endy = firstSelectXYRef.current.y >= endSelectXYRef.current.y ? firstSelectXYRef.current.y : endSelectXYRef.current.y
      
      for(let i = starty; i <= endy; i++) {
        for(let ii = startx; ii <= endx; ii++) {
          selecteds.push(ii + "-" + i)
        }
      }
    }
    setSelectedCells(selecteds)
  }

  return (
    <tbody
      ref={tbodyRef}
      onClick={document.addEventListener('click', documentClickHandler.current)}
      onMouseUp={() => pushSelectedCells()}
      >
      {Object.keys(props.rowsRef.current).map((rowKey, i) => 
        <Row
          key={rowKey}
          y={i}
          row={props.rowsRef.current[rowKey]}
          editableRef={editableRef}
          setEditable={setEditable}
          isSelecting={isSelecting}
          setIsSelecting={setIsSelecting}
          firstSelectXY={firstSelectXY}
          setFirstSelectXY={setFirstSelectXY}
          endSelectXY={endSelectXY}
          setEndSelectXY={setEndSelectXY}
          handleMoveEndSelect={handleMoveEndSelect}
          datasheetRef={props.datasheetRef}
          setDatasheet={props.setDatasheet}
          deleteLine={props.deleteLine}
          setIsEditing={setIsEditing} />
      )}
    </tbody>
  )
}

export default Body;